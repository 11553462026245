import {
  ListItemButton,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import { useNavigate } from "react-router-dom";

const NavSubLink = ({ text, link }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const navigate = useNavigate();

  if (isMobile) {
    return (
      <ListItemButton
        onClick={() => navigate(link)}
        sx={{
          bgcolor: grey[100],
          alignItems: "center",
          justifyContent: "center",
          py: 2,
        }}
      >
        <Typography variant="body2" fontWeight={700} color="primary">
          {text}
        </Typography>
      </ListItemButton>
    );
  }

  return (
    <MenuItem onClick={() => navigate(link)}>
      <Typography variant="body2" fontWeight={700} color="primary">
        {text}
      </Typography>
    </MenuItem>
  );
};

export default NavSubLink;

import { Avatar, Link, Stack, TableCell, TableRow, Typography } from "@mui/material"
import formatNumber from "../../../services/formatNumber"
import useProfitLoss from "../../../services/useProfitLoss";
import { Link as RouterLink } from "react-router-dom"
import { useEffect } from "react";


const ProdukRow = ({ row }) => {
    const [profitLoss, handleProfitLoss] = useProfitLoss(0);

    useEffect(() => {
        handleProfitLoss(row.kinerja_1Y)
    }, [row])

    return (
        <TableRow
            hover
            tabIndex={-1}
            key={row.id}
        >
            <TableCell
                component="th"
                scope="row"
            >
                <Link component={RouterLink} to={`/produk/${row.id}`} color="inherit" underline="none">
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={2}
                    >
                        <Avatar
                            src={row.link_logo}
                        >
                            HP
                        </Avatar>
                        <Typography
                            fontWeight={600}
                            color="primary"
                        >{row.nama_produk}</Typography>
                    </Stack>
                </Link>
            </TableCell>
            <TableCell align="right"><Typography>{formatNumber(row.nav)}</Typography> </TableCell>
            <TableCell align="right">
                <Typography
                    sx={{
                        color: profitLoss ? 'green.main' : 'red.main'
                    }}
                >
                    {formatNumber(row.kinerja_1Y)} %
                </Typography>
            </TableCell>
            <TableCell align="right">
                <Typography
                    sx={{
                        color: row.cagr > 0 ? 'green.main' : 'red.main'
                    }}
                >
                    {formatNumber(row.cagr)} %
                </Typography>
            </TableCell>
            <TableCell align="right"><Typography>{formatNumber(row.aum)}</Typography></TableCell>
        </TableRow>
    )
}

export default ProdukRow
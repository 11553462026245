import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";
import { forwardRef } from "react";

const PromoSteps = forwardRef(({ stepsContent }, ref) => {
  const { heading, image, subheading, items, notes, tncLink } =
    stepsContent || {};

  return (
    <Stack width="100%">
      <HomeContainer>
        <Stack
          direction={{ xs: "column", lg: "row" }}
          sx={{
            width: "100%",
            maxHeight: "80%",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src={image}
            sx={{
              width: { xs: "100%", lg: "45%" },
              maxWidth: { xs: "none", lg: "600px" },
              objectFit: "cover",
            }}
          />
          <Stack
            sx={{
              position: "relative",
              bgcolor: "primary.main",
              justifyContent: "flex-end",
              alignItems: "center",
              width: "100%",
              maxWidth: { xs: "none", lg: "55%" },
              pr: { xs: 2, lg: 6 },
            }}
          >
            <Box
              sx={{
                position: "absolute",
                zIndex: 1,
                width: { xs: "300%", lg: "200%" },
                top: { xs: "none", lg: "50%" },
                bottom: { xs: "-12%", lg: "none" },
                left: { xs: "50%", lg: "-15%" },
                rotate: { xs: "0deg", lg: "0deg" },
                transform: { xs: "translateX(-50%)", lg: "translateY(-50%)" },
                height: { xs: "120%", lg: "250%" },
                borderRadius: "50%",
                bgcolor: "primary.main",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  height: "100%",
                  width: "80%",
                  rotate: { xs: "0deg", lg: "-90deg" },
                  background:
                    "linear-gradient(214deg, rgba(82, 193, 225, 0.05) 31.81%, rgba(87, 205, 238, 0.30) 83.45%);",
                }}
              />
            </Box>
            <Stack
              sx={{
                width: "100%",
                py: { xs: 2, lg: 8 },
                zIndex: 2,
                px: { xs: 2, lg: 0 },
              }}
            >
              <Typography
                color="#FFFFFF"
                fontWeight={600}
                fontSize={{ xs: 24, lg: 32 }}
                lineHeight={1.2}
              >
                {heading}
              </Typography>
              <Typography
                fontSize={{ xs: 16, lg: 24 }}
                color="#FFFFFF"
                fontWeight={300}
              >
                {subheading}
              </Typography>
              <Link
                href={tncLink}
                sx={{
                  mt: 2,
                  width: "fit-content",
                }}
              >
                <Button
                  variant="contained"
                  color="vibrant"
                  disableElevation
                  size="large"
                >
                  Read More
                </Button>
              </Link>
              <Stack
                direction="row"
                gap={4}
                pt={6}
                pb={2}
                width="100%"
                overflow="auto"
                alignItems="center"
              >
                {items.map((item, index) => {
                  return (
                    <Stack
                      key={index}
                      sx={{
                        aspectRatio: "1/1",
                        background: "rgba(255, 255, 255, 0.20);",
                        borderRadius: "100%",
                        width: 124,
                        flexShrink: 0,
                        alignItems: "center",
                        justifyContent: "center",
                        position: "relative",
                        p: 1,
                      }}
                    >
                      <Typography
                        fontSize={32}
                        fontWeight={600}
                        color="#FFFFFF"
                        sx={{
                          top: -20,
                          position: "absolute",
                          right: "50%",
                          transform: "translateX(50%)",
                        }}
                      >
                        {index + 1}
                      </Typography>
                      <Typography
                        textAlign="center"
                        fontSize={14}
                        color="#ffffff"
                      >
                        {item}
                      </Typography>
                    </Stack>
                  );
                })}
              </Stack>
              <Typography
                fontSize={{ xs: 12, lg: 16 }}
                mt={1}
                color="#FFFFFF"
                fontWeight={200}
              >
                {notes}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </HomeContainer>
    </Stack>
  );
});

export default PromoSteps;

import { Box, Button, Link, Stack, Typography } from "@mui/material";
import { HomeContainer } from "../HomeContainer";
import { grey } from "@mui/material/colors";

export default function PromoImageText({
  heading,
  subheading,
  buttonLink,
  buttonText,
  image,
  reverse,
}) {
  return (
    <HomeContainer>
      <Stack
        direction={{ xs: "column", lg: reverse ? "row-reverse" : "row" }}
        gap={{ xs: 4, lg: 10 }}
        width="100%"
        alignItems={{ xs: "flex-start", lg: "center" }}
      >
        <Box
          component="img"
          src={image}
          width="100%"
          height={320}
          sx={{
            objectFit: "cover",
            bgcolor: grey[200],
            border: 0,
            borderRadius: 2,
          }}
        />
        <Stack width="100%">
          <Typography
            color="primary.main"
            fontSize={{xs: 24, lg: 32}}
            fontWeight={600}
            mb={2.5}
            lineHeight={1.2}
          >
            {heading}
          </Typography>
          <Typography
            fontSize={{ xs: 16, lg: 24 }}
            fontWeight={300}
            lineHeight={1.2}
            textAlign="left"
          >
            {subheading}
          </Typography>
          {buttonText && (
            <Link href={buttonLink} mt={4}>
              <Button variant="outlined" color="vibrant">
                {buttonText}
              </Button>
            </Link>
          )}
        </Stack>
      </Stack>
    </HomeContainer>
  );
}

import { Stack, useMediaQuery, useTheme } from "@mui/material"

export const HomeContainer = ({ children, sx }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
    return (
        <Stack
            alignItems="center"
            maxWidth="1440px"
            width="100%"
            sx={{
                p: { md: "60px 120px", xs: "32px 16px" },
                gap: "64px",
                mx: "auto",
                ...sx
            }}>
            {children}
        </Stack>
    )
}
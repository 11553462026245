import { Stack, Typography, useMediaQuery, useTheme } from "@mui/material"

export const HomeHeadings = ({ heading, subheading }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

    return (
        <Stack
            alignItems="center"
            sx={{
                gap: "8px",
                width: "100%",
            }}>
            <Typography
                variant="h2"
                sx={{
                    textAlign: "center",
                }}
            >
                {heading}
            </Typography>
            <Typography
                variant="body1"
                sx={{
                    textAlign: "center",
                    fontSize: isMobile ? "16px" : "24px",
                }}
            >
                {subheading}
            </Typography>
        </Stack>
    )
}
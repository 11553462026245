import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiUrls } from "../services/apiService";

export const getProdukNav = createAsyncThunk(
    'produkNav/getProdukNav',
    async (id) => {
        const res = await api.get(apiUrls?.produk?.getProdukNav.replace("${id}", id));
        return res.data.data;
    }
)

const produkNavSlice = createSlice({
    name: "produkNav",
    initialState: {
        produkNav: [],
        loading: false,
        error: null,
    },

    reducers: {},
    extraReducers: {
        [getProdukNav.pending]: (state) => {
            state.loading = true;
        },
        [getProdukNav.fulfilled]: (state, action) => {
            state.loading = false;
            state.produkNav = action.payload;
        },
        [getProdukNav.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
})

export default produkNavSlice.reducer
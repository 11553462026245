import { HomeContainer } from "../../organisms/HomeContainer";
import ProdukHero from "../../templates/produk/ProdukHero";
import { useEffect } from "react";
import ProductSlider from "../../molecules/sliders/ProductSlider";
import { getProdukData } from "../../../redux/produkDataSlice";
import { useDispatch, useSelector } from "react-redux";
import { Stack } from "@mui/material";
import { ProductCards } from "../../organisms/ProductCards";

const Produk = () => {
  const dispatch = useDispatch();
  const produkData = useSelector((state) => state.produkData.produkData);
  const loading = useSelector((state) => state.produkData.loading);

  useEffect(() => {
    dispatch(getProdukData());
  }, []);

  return (
    <HomeContainer>
      <ProdukHero />
      <Stack
        width="100%"
        height="420px"
      >
        <ProductSlider
          datas={produkData}
          loading={loading}
          component={ProductCards}
          product
        />
      </Stack>
    </HomeContainer>
  );
};

export default Produk;

import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { api, apiUrls } from "../services/apiService";

export const getProdukData = createAsyncThunk(
  "produkData/getProdukData",
  async () => {
    const endpoint = apiUrls?.produk?.getProduk;

    const res = await api.get(endpoint);

    const produkData = res.data.data.produk;

    return produkData;
  }
);

const produkDataSlice = createSlice({
  name: "produkData",
  initialState: {
    produkData: [],
    loading: false,
    error: null,
  },

  reducers: {},
  extraReducers: {
    [getProdukData.pending]: (state) => {
      state.loading = true;
    },
    [getProdukData.fulfilled]: (state, action) => {
      state.loading = false;
      state.produkData = action.payload;
    },
    [getProdukData.rejected]: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export default produkDataSlice.reducer;

import { Box, Link, Stack, Typography } from "@mui/material"

export const FooterLinks = ({ title, datas }) => {
    return (
        <Stack sx={{ color: "#ffffff", gap: "16px" }}>
            <Typography variant="h5" sx={{ color: "#ffff" }}>
                {title}
            </Typography>
            {datas.map((data, idx) => (
                <Link href={data.href} key={idx}>
                    <Typography type="body2" sx={{ color: "#ffff" }}>
                        {data.text}
                    </Typography>
                </Link>
            ))}
        </Stack>
    )
}
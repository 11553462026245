import { useNavigate, useParams } from "react-router-dom";
import { apiUrls, engine } from "../../../services/apiService";
import { useQuery } from "@tanstack/react-query";

export default function Shortlink() {
  const params = useParams();
  const navigate = useNavigate();

  useQuery({
    enabled: !!params?.shortlink,
    queryKey: ["shortlinkData", params?.shortlink],
    queryFn: async () => {
      const res = await engine.get(
        apiUrls.shortlink.getShortlink.replace("${param}", params?.shortlink)
      );

      const resData = res?.data?.data;

      if (!resData.length || !resData) {
        navigate("/404");
      } else {
        window.location.href = resData[0]?.attributes?.destination;
      }
    },
  });

  return <></>;
}

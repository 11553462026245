import { Box, Paper, Stack, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";

export default function PromoSliderCard({ src, heading }) {
  return (
    <Paper
      elevation={0}
      sx={{
        p: 1.5,
        boxSizing: "border-box",
        borderRadius: 2,
      }}
      component={Stack}
      maxWidth={280}
      width="100%"
      height={306}
      gap={1.5}
    >
      <Box
        component="img"
        src={src}
        width="100%"
        height={250}
        borderRadius={2}
        sx={{
          bgcolor: grey[200],
          objectFit: "cover",
        }}
      />
      <Typography fontSize={20} fontWeight={500}>
        {heading}
      </Typography>
    </Paper>
  );
}

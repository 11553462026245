import { useMediaQuery } from "@mui/material";
import Navlink from "../../atoms/Navlink";
import theme from "../../../Theme";

const NavMenus = ({ Navlinks, activePromo }) => {
  {
    return Navlinks.map((data, idx) => {
      return data.link === "Promo" ? (
        activePromo.length > 0 ? (
          <Navlink key={idx} href={data.href} submenu={data.submenu}>
            {data.link}
          </Navlink>
        ) : null
      ) : (
        <Navlink key={idx} href={data.href} submenu={data.submenu}>
          {data.link}
        </Navlink>
      );
    });
  }
};

export default NavMenus;

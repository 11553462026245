import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { Element } from "react-scroll";
import { Button } from "@mui/material";

const PromoSections = ({ data }) => {
  return (
    <Element name={data.title} style={{ width: "100%" }}>
      {!data.__component.includes("button") ? (
        <Stack width="100%" gap={2}>
          <Typography variant="h2" textAlign="left">
            {data.title}
          </Typography>
          <Box
            sx={{ fontSize: 18, lineHeight: 1.5, overflowWrap: "break-word" }}
            dangerouslySetInnerHTML={{ __html: data.description }}
          />
        </Stack>
      ) : (
        <Stack flexGrow={1} alignItems="center" justifyContent="center">
          <Button
            variant="contained"
            sx={{
              borderRadius: 200,
              maxWidth: "360px",
              fontSize: 24,
            }}
            fullWidth
          >
            {data.title}
          </Button>
        </Stack>
      )}
    </Element>
  );
};

export default PromoSections;

import {
  Avatar,
  Button,
  IconButton,
  Link,
  Paper,
  Stack,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { MyHeroLogo } from "../../assets/graphic/logos";
import { useEffect, useState } from "react";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import configuration from "../../services/configuration.json";

export default function DownloadApp() {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const [isShown, setIsShown] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setIsShown(true);
    }
  }, [isMobile]);

  if (!isShown || !isMobile) return null;

  return (
    <Paper
      sx={{
        position: "fixed",
        bottom: 0,
        width: "100%",
        zIndex: 100,
        py: 1.5,
        px: 2,
        pr: 1.5,
        bgcolor: "secondary.main",
        borderRadius: "0px",
      }}
      elevation={0}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <Stack direction="row" gap={1} alignItems="center">
          <Avatar
            src={MyHeroLogo}
            variant="rounded"
            sx={{
              bgcolor: "#fff",
              padding: 1,
              height: 48,
              width: 48,
              borderRadius: "8px",
            }}
          />
          <Stack color="#fff">
            <Typography fontWeight="bold" fontSize={16}>
              {configuration.download_banner.app_name}
            </Typography>
            <Typography fontSize={12} lineHeight={1}>
              {configuration.download_banner.app_description}
            </Typography>
          </Stack>
        </Stack>
        <Stack direction="row" alignItems="center" gap={1.5}>
          <Link href={configuration.home.redirect_link} color="secondary.main">
            <Button
              variant="contained"
              disableElevation
              size="large"
              sx={{
                height: "fit-content",
                bgcolor: "#fff",
                color: "secondary.main",
                "&:hover": {
                  bgcolor: "#fff",
                  color: "secondary.main",
                }
              }}
            >
              Download
            </Button>
          </Link>
          <IconButton
            size="small"
            onClick={() => setIsShown(false)}
            sx={{
              p: 0,
            }}
          >
            <CloseRoundedIcon
              sx={{
                color: "#fff",
              }}
            />
          </IconButton>
        </Stack>
      </Stack>
    </Paper>
  );
}

import { Skeleton, Stack } from "@mui/material"
import { BlogContentHome } from "../../templates/BlogContentHome"
import { BlogHero } from "../../templates/BlogHero"
import { engine, apiUrls } from "../../../services/apiService"
import { useEffect, useState } from "react"
import seperateCategory from "../../../services/seperateCategory"
import { BlogGrid } from "../../organisms/BlogGrid"


export const Blog = () => {
    const [blogsData, setBlogsData] = useState([])
    const [types, setTypes] = useState([])
    const [loading, setLoading] = useState(true)
    const [search, setSearch] = useState(false)
    const [searchTerm, setSearchTerm] = useState("");


    useEffect(() => {
        getBlogsData();
    }, [])

    useEffect(() => {
        if (blogsData.length > 0) {
            setTypes(seperateCategory(blogsData))
            setLoading(false)
        }
    }, [blogsData])


    async function getBlogsData() {
        try {
            const res = await engine.get(apiUrls?.blog?.getBlogPosts);
            setBlogsData(res.data.data);
        } catch (err) {
            console.log(err);
        }
    }

    return (
        <Stack>
            <BlogHero
                category={types}
                loading={loading}
                search={search}
                setSearch={setSearch}
                searchTerm={searchTerm}
                setSearchTerm={setSearchTerm}
            />
            {searchTerm != "" ?
                <BlogGrid
                    datas={blogsData}
                    searchTerm={searchTerm}
                />
                :
                <BlogContentHome data={blogsData} category={types} />
            }
        </Stack>
    )
}   
import React from "react";
import { Box, Link, Skeleton, Stack, Typography } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import FormatDate from "../../../services/FormatDate";

const BlogCards = ({ datas }) => {
  const [loading, setLoading] = React.useState(true);
  React.useEffect(() => {
    if (datas) {
      setLoading(false);
    }
  }, [datas]);

  return (
    <Stack
      sx={{
        width: "100%",
        maxWidth: "360px",
        height: "100%",
      }}
    >
      <Link
        component={RouterLink}
        to={`/blog/${datas?.attributes?.slug}`}
        sx={{
          textDecoration: "none",
          height: "100%",
        }}
      >
        <Stack
          sx={{
            height: "100%",
          }}
        >
          <Stack
            overflow="hidden"
            alignItems="center"
            justifyContent="center"
            sx={{
              height: "180px",
              minHeight: "180px",
              objectFit: "cover",
            }}
          >
            {loading ? (
              <Skeleton width="100%" height={210} />
            ) : (
              <Box
                component="img"
                src={datas?.attributes?.image.data.attributes?.url}
                maxWidth="600px"
                maxHeight="210px"
                styles={{
                  objectFit: "cover",
                }}
              />
            )}
          </Stack>
          <Stack
            justifyContent="space-between"
            sx={{
              gap: "16px",
              pt: "16px",
              maxWidth: "360px",
              height: "100%",
              width: "100%",
            }}
          >
            <Stack
              sx={{
                gap: "16px",
              }}
            >
              <Typography variant="h4">
                {loading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  datas?.attributes?.title
                )}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: "#000000",
                }}
              >
                {loading ? (
                  <>
                    <Skeleton />
                    <Skeleton />
                    <Skeleton />
                  </>
                ) : (
                  datas?.attributes?.subtitle
                )}
              </Typography>
            </Stack>
            {loading ? (
              <Skeleton width={100} height={24} />
            ) : (
              <Stack
                direction="row"
                sx={{
                  gap: "16px",
                }}
              >
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                  }}
                >
                  {" "}
                  {datas?.attributes?.writer}{" "}
                </Typography>
                <Typography
                  variant="body2"
                  sx={{
                    fontSize: "13px",
                    color: "grey.grey500",
                  }}
                >
                  {" "}
                  {FormatDate(datas?.attributes?.publishedAt)}{" "}
                </Typography>
              </Stack>
            )}
          </Stack>
        </Stack>
      </Link>
    </Stack>
  );
};

export default BlogCards;

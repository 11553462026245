const formatNumber = (value) => {
  if (typeof value === 'number') {
    if (value >= 1000000000) {
      return `Rp. ${parseFloat(value / 1000000000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}M`;
    } else if (value >= 1000000) {
      return `Rp. ${parseFloat(value / 1000000).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}jt`;
    }
    return value.toFixed(2)
  } else {
    let parsedValue = parseFloat(value);
    if (!isNaN(parsedValue)) {
      if (parsedValue >= 1000000000) {
        return `Rp. ${parseFloat(parsedValue / 1000000000).toFixed(2).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}M`;
      } else if (parsedValue >= 100000) {
        return `Rp. ${parseFloat(parsedValue / 1000000).toFixed().toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")}jt`;
      }
      return parsedValue.toFixed(1);
    } else {
      return '-';
    }
  }
};

export default formatNumber;

import { Accordion, AccordionDetails, AccordionSummary, Typography } from "@mui/material"
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

export const HomeAccordion = ({ datas }) => {

    return (
        <>
            {datas.map((accordion, idx) => {
                const { title, body } = accordion;
                return (
                    <Accordion key={idx}
                        sx={{
                            width: "100%",
                            boxShadow: "0"
                        }}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon sx={{ color: "primary.main" }} />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                p: "24px 0"
                            }}
                        >
                            <Typography variant="h3">
                                {title}
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Typography>
                                {body}
                            </Typography>
                        </AccordionDetails>
                    </Accordion>
                );
            })}
        </>
    )
}
import { Button, Stack } from "@mui/material"
import PageHeader from "../../molecules/PageHeader"


const ProdukHero = () => {
    return (
        <Stack
            alignItems="center"
            sx={{ gap: "32px" }}
        >
            <PageHeader title="Reksa Dana Terbaik" subtitle="#SegampangItu untuk mulai investasi" />
            <Button variant="contained"
                disableElevation
                href="/download"
                sx={{
                    p: "12px 32px",
                    borderRadius: "200px",
                    fontSize: "16px",
                    width: "fit-content",
                }}>
                
                Mulai Investasi Sekarang!
            </Button>
        </Stack>
    )
}

export default ProdukHero
import { Paper, Stack, Typography } from "@mui/material"
import formatNumber from "../../../services/formatNumber"
import ProdukSummaryItems from "../../molecules/ProdukSummaryItems"

const ProdukDetailSummary = ({ data }) => {
    return (
        <Paper
            elevation={4}
            sx={{
                display: "flex",
                borderRadius: 3,
                p: "16px 32px",
                flexDirection: "row",
                justifyContent: "space-between",
                alignContent: "center",
                flexWrap: "wrap",
                gap: "32px"
            }}
        >
            <ProdukSummaryItems
                title="NAV"
                item={data.nav}
            />
            <ProdukSummaryItems
                title="Total AUM"
                item={data.aum}
            />
            <ProdukSummaryItems
                title="Return 1 Year"
                item={data.kinerja_1Y}
            />
            <ProdukSummaryItems
                title="CAGR"
                item={data.cagr}
            />
        </Paper>
    )
}

export default ProdukDetailSummary
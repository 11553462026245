import { useState, useMemo } from 'react';

const useProfitLoss = (initialValue) => {
    const [profitLoss, setProfitLoss] = useState(initialValue > 0);

    const handleProfitLoss = useMemo(() => {
        return (value) => {
            if (value > 0) {
                setProfitLoss(true);
            } else {
                setProfitLoss(false);
            }
        };
    }, [setProfitLoss]);

    return [profitLoss, handleProfitLoss];
};

export default useProfitLoss;

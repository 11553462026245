import { Box } from "@mui/material"
import { useState } from "react"
import { BlogHeroCard } from "../molecules/Cards"
import { BlogArticlesRow } from "../organisms/BlogArticlesRow"
import { HomeContainer } from "../organisms/HomeContainer"



export const BlogContentHome = ({ data, category }) => {
    return (
        <HomeContainer>
            {data.map((item, idx) => {
                if (idx === 0) {
                    return (
                        <BlogHeroCard data={item} key={idx} />
                    )
                }
            })}
            {category.map((item, idx) => (
                <BlogArticlesRow data={item} key={idx}/>
            ))}
        </HomeContainer>
    )
}
import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import Grid2 from '@mui/material/Unstable_Grid2'
import { AppHome, Berhasil, Kantong, Menabung, Saly } from "../../../assets/graphic/images"
import { SquareCards } from "../../molecules/Cards"
import { HomeHeadings } from "../../molecules/HomeHeadings"
import { HomeContainer } from "../../organisms/HomeContainer"


const cardsData = [{
    illustration: Saly,
    title: "#SegampangItu Investasi",
    body: "Membantu sesuaikan investasimu dengan tujuan keuangan kamu"
},
{
    illustration: Kantong,
    title: "Ambil keuntungan dengan gampang",
    body: "Ambil keuntungan investasimu, kapan saja, dimana saja",
},
{
    illustration: Menabung,
    title: "Investasi Mulai Dari Rp50.000",
    body: "Tanpa perlu modal besar, kamu bisa langsung mulai investasi",
},
{
    illustration: Berhasil,
    title: "Gratis Biaya Komisi",
    body: "Investasi tanpa biaya ina-inu",
},
]

export const BenefitSection = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
    return (
        <HomeContainer>
            <HomeHeadings
                heading={<>Bagaimana <Typography component="span" variant="h2" sx={{ display: "inline", color: "vibrant.main" }}>MyHero</Typography> Bisa Membantumu?</>}
                subheading={<>Dengan fitur terbaik, investasimu jadi lebih gampang!</>}
            />
            <Stack
                direction="row"
                justifyContent="center"
                alignItems="center"
                sx={{
                    gap: "72px"
                }}
            >
                <Box
                    component="img"
                    src={AppHome}
                    width={303.16}
                    height={640}
                    sx={{
                        display: isMobile ? "none" : "block",
                        boxShadow: "1px 1px 32px rgba(0, 0, 0, 0.15)",
                        borderRadius: "16px"
                    }}
                />
                <Stack>
                    <Grid2
                        container
                        spacing={2}
                        maxWidth="666px"
                    >
                        {cardsData.map((data, idx) => (
                            <SquareCards
                                key={idx}
                                illustration={data.illustration}
                                title={data.title}
                                body={data.body}
                            />
                        ))}
                    </Grid2>
                </Stack>

            </Stack>
        </HomeContainer >
    )
}
import Stack from "@mui/material/Stack";
import NumbersItems from "../../molecules/NumbersItems";
import useMediaQuery from "@mui/material/useMediaQuery";
import { HomeContainer } from "../../organisms/HomeContainer";

const numberItems = [
    {
        number: "12.511",
        title: "Investor"
    },
    {
        number: "8,23T",
        title: "Dana Pengelolaan"
    },
    {
        number: "18",
        title: "Tahun Pengalaman"
    },
    {
        number: "9",
        title: "Penghargaan"
    },
]

const TentangKamiNumbers = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"))

    return (
        <HomeContainer
            sx={{
                bgcolor: "primary.main",
                maxWidth: null,
                m: 0,
            }}
        >
            <Stack
                direction="row"
                justifyContent={"space-between"}
                gap={isMobile ? 6 : 8}
                overflow="auto"
                width="100%"
                maxWidth="1000px"
            >
                {numberItems.map((item, index) => (
                    <NumbersItems
                        key={index}
                        item={item}
                    />
                ))}
            </Stack>
        </HomeContainer>
    )
}

export default TentangKamiNumbers
import Stack from "@mui/material/Stack";
import PromoSections from "../../organisms/promo/PromoSections";
import React from "react";
import { Divider } from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";

const PromoContent = ({ datas }) => {
  return (
    <HomeContainer>
      <Stack
        alignItems="center"
        gap={3}
      >
        {datas?.map((data, idx) => (
          <React.Fragment key={data.id}>
            <PromoSections data={data} />
            {idx !== datas.length - 1 && <Divider flexItem />}
          </React.Fragment>
        ))}
      </Stack>
    </HomeContainer>
  );
};

export default PromoContent;

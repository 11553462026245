import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { engine, apiUrls } from "../services/apiService";

export const getPromoData = createAsyncThunk(
    'promoData/getPromoData',
    async (slug) => {
        const res = await engine.get(apiUrls?.promo?.getPromoBySlug.replace("${slug}", slug));
        return res.data.data[0].attributes;
    }
)
    
const promoDataSlice = createSlice({
    name: "promoData",
    initialState: {
        promoData: [],
        loading: false,
        error: null,
    },

    reducers: {},
    extraReducers: {
        [getPromoData.pending]: (state) => {
            state.loading = true;
        },
        [getPromoData.fulfilled]: (state, action) => {
            state.loading = false;
            state.promoData = action.payload;
        },
        [getPromoData.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        }
    }
})

export default promoDataSlice.reducer
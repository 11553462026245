import { Box, Link, Stack, Typography } from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";
import { AppStore, PlayStore } from "../../../assets/graphic/logos";

export default function PromoDownload({ downloadContent }) {
  const { heading, subheading, image, links } = downloadContent;

  return (
    <Stack>
      <HomeContainer>
        <Stack
          direction={{ xs: "column-reverse", lg: "row-reverse" }}
          sx={{
            width: "100%",
            borderRadius: 2,
            overflow: "hidden",
          }}
        >
          <Box
            component="img"
            src={image}
            height={360}
            zIndex={1}
            sx={{
              objectFit: "cover",
            }}
          />
          <Stack
            sx={{
              height: "auto",
              position: "relative",
              zIndex: 2,
              bgcolor: "primary.main",
              width: "100%",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                zIndex: 1,
                left: { xs: "50%", lg: "-40%" },
                width: { xs: "250%", lg: "150%" },
                top: { xs: "-10%", lg: "50%" },
                transform: { xs: "translateX(-50%)", lg: "translateY(-50%)" },
                height: { xs: "120%", lg: "250%" },
                borderRadius: "50%",
                bgcolor: "primary.main",
                overflow: "hidden",
              }}
            >
              <Box
                sx={{
                  height: { xs: "100%", lg: "120%" },
                  width: "100%",
                  rotate: { xs: "0deg", lg: "-90deg" },
                  background:
                    "linear-gradient(214deg, rgba(82, 193, 225, 0.05) 31.81%, rgba(87, 205, 238, 0.30) 83.45%);",
                }}
              />
            </Box>
            <Stack
              width="100%"
              height="80%"
              justifyContent="space-between"
              alignItems={{ xs: "center", lg: "flex-start" }}
              gap={3}
              sx={{
                pb: { xs: 4, lg: 4 },
                pt: { xs: 8, lg: 8 },
                zIndex: 2,
                px: { xs: 4, lg: 8 },
                textAlign: { xs: "center", lg: "left" },
              }}
            >
              <Stack gap={3}>
                <Typography
                  color="#FFFFFF"
                  fontWeight={600}
                  fontSize={{ xs: 24, md: 36, lg: 48 }}
                  lineHeight={1}
                >
                  {heading}
                </Typography>
                <Typography
                  fontSize={{ xs: 14, md: 16, lg: 20 }}
                  lineHeight={1.2}
                  color="#FFFFFF"
                  fontWeight={300}
                >
                  {subheading}
                </Typography>
              </Stack>
              <Stack
                direction="row"
                sx={{
                  display: "flex",
                  width: "fit-content",
                  gap: "12px",
                  mt: { xs: 2, lg: 0 },
                }}
              >
                <Link href={links.playstore}>
                  <Box
                    component="img"
                    src={PlayStore}
                    maxWidth={179}
                    width="100%"
                    height="auto"
                  />
                </Link>
                <Link href={links.appstore}>
                  <Box
                    component="img"
                    src={AppStore}
                    maxWidth={179}
                    width="100%"
                    height="auto"
                  />
                </Link>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </HomeContainer>
    </Stack>
  );
}

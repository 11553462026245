import { Stack, useMediaQuery } from "@mui/material"
import { useEffect } from "react"
import useProfitLoss from "../../../services/useProfitLoss"
import ProductChart from "../../molecules/charts/ProductChart"
import ProdukDetailHeader from "../../organisms/produk/ProdukDetailHeader"
import ProdukDetailSummary from "../../organisms/produk/ProdukDetailSummary"


const ProdukDetailChart = ({ data, nav, loading }) => {
    const [profitLoss, handleProfitLoss] = useProfitLoss(0);
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"))

    useEffect(() => {
        handleProfitLoss(data.kinerja_1Y)
    }, [data.kinerja_1Y])


    return (
        <Stack
            width="100%"
            gap={isMobile ? 4 : 8}
        >
            <ProdukDetailHeader data={data} loading={loading} profitLoss={profitLoss} />
            <Stack height={isMobile ? "360px" : "560px"}>
                <ProductChart data={nav.kinerja} profitLoss={profitLoss} />
            </Stack>
            <ProdukDetailSummary data={data} />

        </Stack>
    )
}

export default ProdukDetailChart
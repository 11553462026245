import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import MenuItem from "@mui/material/MenuItem";

const FieldCalc = ({ datas, fields, state, handleForm, submitted }) => {
  const isFieldEmpty = state[fields.name] === "" || state[fields.name] === null;

  return (
    <TextField
      name={fields.name}
      label={fields.label}
      select={fields.select}
      value={state[fields.name] || ""}
      onChange={handleForm}
      placeholder={fields.placeholder}
      InputProps={{
        startAdornment: fields.select ? null : (
          <InputAdornment
            position="start"
            sx={{
              "& .MuiTypography-root": {
                fontSize: "14px",
                color: "#000000",
              },
            }}
          >
            {fields.startAdornment}
          </InputAdornment>
        ),
      }}
      required
      error={submitted && isFieldEmpty}
      helperText={submitted && isFieldEmpty ? "This field is required" : ""}
      sx={{
        width: "100%",
        "& label": {
          color: "primary.main",
          fontWeight: "bold",
        },
        "& .MuiOutlinedInput-root": {
          "& fieldset": {
            borderColor:
              submitted && isFieldEmpty ? "error.main" : "primary.main",
          },
        },
        "& .MuiTypography-root": {
          color: "primary.main",
        },
        "& input": {
          fontFamily: "TT Commons",
        },
      }}
    >
      {fields.select &&
        datas.map((data) => (
          <MenuItem key={data.id} value={data.id}>
            {data.nama_produk}
          </MenuItem>
        ))}
    </TextField>
  );
};

export default FieldCalc;

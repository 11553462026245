import { HomeContainer } from "../../organisms/HomeContainer"
import Stack from "@mui/material/Stack"
import Box from "@mui/material/Box"
import useMediaQuery from "@mui/material/useMediaQuery"
import TentangKamiTitle from "../../organisms/tentang-kami/TentangKamiTitle"
import { TentangKamiimg } from "../../../assets/graphic/images"


const TentangKamiHero = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"))

    return (
        <HomeContainer>
            <Stack
                direction={isMobile ? "column-reverse" : "row"}
                width="100%"
                alignItems="center"
                justifyContent="space-between"
                gap={8}
            >
                <TentangKamiTitle />
                <Box
                    component="img"
                    src={TentangKamiimg}
                    sx={{
                        width: "100%",
                        height: "auto",
                        maxHeight: "400px",
                        maxWidth: "400px"
                    }}
                />
            </Stack>
        </HomeContainer>
    )
}

export default TentangKamiHero
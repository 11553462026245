import { Stack, Typography } from "@mui/material"

const PageHeader = ({ title, subtitle }) => {
    return (
        <Stack
            direction="column"
            alignItems="center"
            sx={{
                gap: "8px"
            }}
        >
            <Typography variant="h1">
                {title}
            </Typography>
            <Typography variant="body1" sx={{
                textAlign: "center",
            }}>
                {subtitle}
            </Typography>
        </Stack>
    )
}

export default PageHeader
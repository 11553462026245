import { Box, useTheme, useMediaQuery, Stack} from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";
import { grey } from "@mui/material/colors";

export default function PromoVideo({
  videoContent
}) {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <HomeContainer>
      <Stack
        direction={{ xs: "column"}}
        gap={{ xs: 4, lg: 10 }}
        width="100%"
        alignItems={{ xs: "flex-start", lg: "center" }}
      >
        <Box
          component="iframe"
          src={videoContent}
          width="100%"
          height={isMobile ? "360px" : "540px"}
          sx={{
            objectFit: "cover",
            bgcolor: grey[200],
            border: 0,
            borderRadius: 2,
          }}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        />
      </Stack>
    </HomeContainer>
  );
}

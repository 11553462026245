import {
  Box,
  Button,
  Collapse,
  Divider,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
  useMediaQuery,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import React from "react";
import ArrowDropDownRoundedIcon from "@mui/icons-material/ArrowDropDownRounded";
import NavSubLink from "./NavSubLink";
import { grey } from "@mui/material/colors";

const Navlink = ({ children, href, submenu }) => {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [openSubMenu, setOpenSubMenu] = React.useState(false);

  function handleClick(event) {
    setAnchorEl(event.currentTarget);
  }

  function handleMobileClick() {
    setOpenSubMenu(!openSubMenu);
  }

  function handleClose() {
    setAnchorEl(null);
  }

  return (
    <>
      <Box
        component={isMobile ? ListItemButton : Button}
        onClick={() =>
          submenu
            ? isMobile
              ? handleMobileClick()
              : handleClick()
            : href.includes("https")
            ? window.location.replace(href)
            : navigate(href)
        }
        {...(!isMobile
          ? {
              endIcon: submenu && <ArrowDropDownRoundedIcon />,
              onMouseOver: submenu ? handleClick : null,
            }
          : {})}
        sx={{
          cursor: "pointer",
          color: "primary.main",
          p: "8px 16px",
          transition: "all 300ms",
          padding: isMobile ? 2 : null,
          "&:hover": {
            bgcolor: "rgb(230, 230, 230,0.2)",
            color: "secondary.main",
          },
          textAlign: "center",
        }}
      >
        <ListItemText
          primaryTypographyProps={{
            display: "flex",
            direction: "row",
            alignItems: "center",
            justifyContent: "center",
            marginLeft: isMobile && submenu ? 2 : null,
          }}
        >
          <Typography variant="body2" fontWeight={700} lineHeight={1.2}>
            {children}
          </Typography>
          {isMobile && submenu && <ArrowDropDownRoundedIcon />}
        </ListItemText>
      </Box>
      {isMobile ? (
        <Collapse in={openSubMenu}>
          <Divider />
          <List component="div" disablePadding>
            {submenu?.map((item) => (
              <NavSubLink
                key={item.attributes.promo_slug}
                text={item.attributes.promo_name}
                link={"/promo/" + item.attributes.promo_slug}
              />
            ))}
          </List>
          <Divider />
        </Collapse>
      ) : (
        <Menu
          anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleClose}
          MenuListProps={{ onMouseLeave: handleClose }}
        >
          {submenu?.map((item) => (
            <NavSubLink
              key={item.attributes.promo_slug}
              text={item.attributes.promo_name}
              link={"/promo/" + item.attributes.promo_slug}
            />
          ))}
        </Menu>
      )}
    </>
  );
};

export default Navlink;

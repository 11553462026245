import { Box, Button, Input, Skeleton, Stack, Typography } from "@mui/material";
import TitleFormatter from "../../services/TitleFormatter";
import { ButtonCategory } from "../atoms/Buttons";
import { HomeContainer } from "../organisms/HomeContainer";
import SearchRoundedIcon from "@mui/icons-material/SearchRounded";
import { SearchBar } from "../molecules/SearchBar";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import PageHeader from "../molecules/PageHeader";

export const BlogHero = ({
  category,
  search,
  setSearch,
  searchTerm,
  setSearchTerm,
  setCategorySelect,
  loading,
}) => {
  const [categoryDest, setCategoryDest] = useState(null);
  const route = useLocation();

  const handleCategory = (data) => {
    if (route.pathname === "/blog/posts") {
      setCategoryDest(data.category);
      setCategorySelect(data.category);
    }
  };

  return (
    <HomeContainer sx={{ gap: "32px", pb: "32px" }}>
      <PageHeader
        title="Gampang Belajar"
        subtitle="Bantu kamu tahu lebih banyak soal keuangan"
      />
      <Stack
        alignItems="center"
        justifyContent="flex-start"
        direction="column"
        sx={{
          gap: "32px",
          width: "100%",
        }}
      >
        {search ? (
          <Stack alignItems="center" flexShrink={0} width="100%" gap={2}>
            <SearchBar
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              setSearch={setSearch}
            />
            {searchTerm != "" ? (
              <Stack alignItems="center" mt={2}>
                <Typography variant="body2" color="grey">
                  Kata kunci :
                </Typography>
                <Typography variant="body1" color="primary">
                  "{searchTerm}"
                </Typography>
              </Stack>
            ) : null}
          </Stack>
        ) : null}
        {loading ? (
          <Skeleton variant="rectangular" width="400px" height="48px" />
        ) : search ? null : (
          <Stack
            alignItems="center"
            justifyContent="center"
            direction="row"
            sx={{
              gap: "8px",
              width: "100%",
              flexWrap: "wrap",
            }}
          >
            {category.map((data, idx) => (
              <ButtonCategory
                key={idx}
                href={
                  categoryDest && route.pathname === "/blog/posts"
                    ? null
                    : data.category
                }
                onClick={() => handleCategory(data)}
                scroll={true}
              >
                <Typography>
                  <TitleFormatter input={data.category} />
                </Typography>
              </ButtonCategory>
            ))}
            <Button
              disableElevation
              variant="contained"
              onClick={() => setSearch(true)}
              sx={{
                borderRadius: "200px",
                height: "41px",
              }}
            >
              <SearchRoundedIcon />
            </Button>
          </Stack>
        )}
      </Stack>
    </HomeContainer>
  );
};

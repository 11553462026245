import { HomeHeadings } from "../../molecules/HomeHeadings"
import { HomeContainer } from "../../organisms/HomeContainer"
import Stack from "@mui/material/Stack"
import ProdukCalc from "../../organisms/home/ProdukCalc"
import SimulasiChart from "../../molecules/charts/SimulasiChart"
import { useState } from "react"
import { Typography } from "@mui/material"

const SimulasiSection = ({ datas }) => {
    const [hasilInvestasi, setHasilInvestasi] = useState(
        [...Array(25)].map((_, i) => {
            const m = (i + 1) * 12;
            const r = 0.004;
            const pv = 1000000 * Math.pow(1 + r, m) + (1000000 * (((Math.pow(1 + r, m) - 1) / r)));
            const uv = 1000000 + (1000000 * m);
            const tahun = `${i + 1} Tahun`;
            return { tahun, pv, uv };
        })
    );

    return (
        <HomeContainer>
            <HomeHeadings
                heading={<>Simulasikan <Typography component="span" variant="h2" sx={{ display: "inline", color: "vibrant.main" }}>Investasimu</Typography></>}
                subheading={<>Dapatkan gambaran mengenai investasimu dimasa mendatang</>}
            />
            <Stack
                direction={{ xs: "column-reverse", lg: "row" }}
                justifyContent="center"
                alignItems={{xs: "center" ,lg:"start"}}
                width="100%"
                gap={{xs: 2, lg:4 }}
            >
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    width="100%"
                    maxWidth="420px"
                >
                    <ProdukCalc
                        datas={datas}
                        hasilInvestasi={hasilInvestasi}
                        setHasilInvestasi={setHasilInvestasi}
                    />
                </Stack>
                <Stack
                    width="100%"
                    maxWidth="720px"
                >
                    <SimulasiChart
                        data={hasilInvestasi}
                    />
                </Stack>
            </Stack>
        </HomeContainer>
    )
}

export default SimulasiSection
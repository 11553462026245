export default function TwoElipse() {
  return (
    <svg
      width="120"
      height="82"
      viewBox="0 0 120 82"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="54.5" cy="78.5" r="54.5" fill="#D9D9D9" fillOpacity="0.2" />
      <circle cx="102.5" cy="17.5" r="17.5" fill="#D9D9D9" fillOpacity="0.2" />
    </svg>
  );
}

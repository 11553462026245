import React, { Component, useRef, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";

import styles from "./product-slider.module.css";

// import required modules
import { Skeleton, useTheme } from "@mui/material";
import { Stack } from "@mui/system";

export default function ProductSlider({
  component: Component,
  datas,
  loading,
  navArrows,
  product,
  chart,
}) {
  const theme = useTheme();
  const breakpoints = product
    ? {
        1200: { slidesPerView: 4.2 },
        720: { slidesPerView: 3.2 },
        540: { slidesPerView: 2.2 },
        0: { slidesPerView: 1.2 },
      }
    : {
        1200: {
          slidesPerView: datas.length > 3 ? 3.2 : 3.5,
        },
        540: {
          slidesPerView: 2.2,
        },
        0: {
          slidesPerView: 1.2,
        },
      };

  return (
    <Swiper
      breakpoints={breakpoints}
      spaceBetween={16}
      navigation={navArrows}
      pagination={{
        clickable: true,
      }}
      modules={[Navigation]}
      className={styles.swiper}
      style={{
        "--swiper-pagination-color": theme.palette.primary.main,
        height: "100%",
      }}
    >
      {loading
        ? Array.from({ length: 7 }, (_, index) => (
            <SwiperSlide className={styles.swiperSlide} key={index}>
              <Stack width="100%" height="100%">
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height="320px"
                  sx={{
                    borderRadius: "8px",
                  }}
                />
              </Stack>
            </SwiperSlide>
          ))
        : datas.map((data, idx) => (
            <SwiperSlide key={idx} className={styles.swiperSlide}>
              <Component datas={data} loading={loading} chart={chart} />
            </SwiperSlide>
          ))}
    </Swiper>
  );
}

import { Box, Button, Stack, Typography } from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";

export default function PromoHero({ heroContent, onReadMoreClick }) {
  const { heading, subheading, src } = heroContent || {};

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "primary.main",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <HomeContainer
        sx={{
          p: { md: "32px 120px", xs: "24px 16px" },
          zIndex: 2,
        }}
      >
        <Stack
          direction={{ xs: "column-reverse", lg: "row" }}
          justifyContent="space-between"
          alignItems="center"
        >
          <Stack
            sx={{
              color: "#FFFFFF",
            }}
          >
            <Typography fontSize={{ xs: 20, lg: 24 }} mb={3} fontWeight={300}>
              {subheading}
            </Typography>
            <Typography
              fontSize={{
                xs: 32,
                lg: 52,
              }}
              fontWeight={800}
              lineHeight={1.2}
              color="#FFFFFF"
            >
              {heading}
            </Typography>
            <Button
              variant="contained"
              disableElevation
              onClick={onReadMoreClick}
              size="large"
              color="vibrant"
              sx={{
                width: "fit-content",
                borderRadius: 1,
                mt: 4,
              }}
            >
              Read More
            </Button>
          </Stack>
          <Box
            component="img"
            src={src}
            alt="hiro"
            sx={{
              height: "auto",
              width: "100%",
              maxWidth: 572,
            }}
          />
        </Stack>
      </HomeContainer>
      <Box
        sx={{
          position: "absolute",
          flexShrink: 0,
          left: "-40%",
          top: { xs: "75%", lg: "50%" },
          transform: "translate(0, -50%)",
          height: { xs: "100%", lg: "200%" },
          width: "100%",
          zIndex: 1,
          borderRadius: "50%",
          background: `linear-gradient(214deg, rgba(82, 193, 225, 0.05) 31.81%, rgba(87, 205, 238, 0.30) 83.45%)`,
        }}
      />
    </Stack>
  );
}

import { useEffect, useState, useMemo } from "react"
import { useDispatch, useSelector } from "react-redux"
import { useParams } from "react-router"
import { getProdukData } from "../../../redux/produkDataSlice"
import { getProdukNav } from "../../../redux/produkNavSlice"
import { HomeContainer } from "../../organisms/HomeContainer"
import ProdukTable from "../../organisms/produk/ProdukTable"
import ProdukDetailChart from "../../templates/produk/ProdukDetailChart"

//coba pake usememo untuk stop rerender

const ProdukDetail = () => {
    const { id } = useParams()
    const dispatch = useDispatch()
    const produkNav = useSelector(state => state.produkNav.produkNav);
    const produkData = useSelector(state => state.produkData.produkData);
    const loading = useSelector(state => state.produkNav.loading);

    useEffect(() => {
        dispatch(getProdukData())
        dispatch(getProdukNav(id))
    }, [id])

    const selectedProdukData = useMemo(() => {
        return produkData.find(data => data.id == id) || {}
    }, [produkData,id])

    return (
        <HomeContainer>
            <ProdukDetailChart data={selectedProdukData} nav={produkNav} loading={loading} />
            <ProdukTable datas={produkData}/>
        </HomeContainer>
    )
}

export default ProdukDetail
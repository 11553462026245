import Paper from '@mui/material/Paper'
import Stack from '@mui/material/Stack'
import Button from '@mui/material/Button'
import Grid from '@mui/material/Grid'
import { useState } from 'react'
import FieldCalc from '../../molecules/form/FieldCalc'

const fields = [
    {
        name: "produk",
        label: "Produk",
        placeholder: "pilih produk",
        select: true,
    },
    {
        name: "awal",
        label: "Investasi Awal",
        placeholder: "1.000.000",
        startAdornment: "Rp",
    },
    {
        name: "bulanan",
        label: "Investasi Bulanan",
        placeholder: "1.000.000",
        startAdornment: "Rp",
    },
]

const ProdukCalc = ({ datas, hasilInvestasi, setHasilInvestasi }) => {
    const [form, setForm] = useState({
        produk: null,
        awal: null,
        bulanan: null,
    })
    const [submitted, setSubmitted] = useState(false)

    const handleForm = (e) => {
        const fieldName = e.target.name;
        let fieldValue = e.target.value;

        if (fieldName !== "produk") {
            fieldValue = fieldValue.replace(/[^0-9]/g, "");
            fieldValue = fieldValue.replace(/\B(?=(\d{3})+(?!\d))/g, ".");
        }

        setForm({
            ...form,
            [fieldName]: fieldValue,
        });
    }

    const hitungInvestasi = (e) => {
        e.preventDefault();

        const awal = form.awal.replace(/\./g, "");
        const bulanan = form.bulanan.replace(/\./g, "");

        const filteredDatas = datas.filter((data) => data.cagr > 0);
        const produk = filteredDatas.find((data) => data.id === form.produk);
        const cagr = produk.cagr / 100;
        const r = cagr / 12;
        const invAwal = Number(awal);
        const monthlyDeposit = Number(bulanan);
        const hasilInvestasi = [...Array(25)].map((_, i) => {
            const m = (i + 1) * 12;
            const pv = invAwal * Math.pow(1 + r, m) + (monthlyDeposit * (((Math.pow(1 + r, m) - 1) / r)));
            const uv = invAwal + (monthlyDeposit * m);
            return {
                tahun: (i + 1) + " Tahun",
                pv: parseInt(pv),
                uv: parseInt(uv),
            };
        });

        setHasilInvestasi(hasilInvestasi);
    };

    return (
        <Paper elevation={4}
            sx={{
                width: '100%',
                borderRadius: '10px',
                p: 3
            }}
        >
            <Stack
                gap={3}
                justifyContent="center"
                alignItems="center"
                component="form"
                width="100%"
                onSubmit={hitungInvestasi}
            >
                <Grid container spacing={{ xs: 1.5, md: 3 }}>
                    {fields.map((field, index) => (
                        <Grid item xs={field.select ? 12 : 6} md={12} key={index}>
                            <FieldCalc
                                datas={datas}
                                fields={field}
                                submitted={submitted}
                                state={form}
                                handleForm={handleForm}
                            />
                        </Grid>
                    ))}
                </Grid>
                <Button
                    variant="contained"
                    size="large"
                    disableElevation
                    fullWidth
                    type='submit'
                    onClick={() => setSubmitted(true)}
                >
                    Hitung
                </Button>
            </Stack>
        </Paper >
    )
}

export default ProdukCalc
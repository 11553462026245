import Stack from "@mui/material/Stack";
import { HomeHeadings } from "../../molecules/HomeHeadings";
import ProductSlider from "../../molecules/sliders/ProductSlider";
import { HomeContainer } from "../../organisms/HomeContainer";
import { Typography } from "@mui/material";
import { ProductCards } from "../../organisms/ProductCards";

export const ProductSection = ({ datas, loading }) => {
  return (
    <HomeContainer>
      <HomeHeadings
        heading={
          <>
            Pilihan Produk di{" "}
            <Typography
              component="span"
              variant="h2"
              sx={{ display: "inline", color: "vibrant.main" }}
            >
              MyHero
            </Typography>
          </>
        }
        subheading={
          <>
            Bebas pilih produk sesuai dengan karakteristik dan keinginan kamu!
          </>
        }
      />
      <Stack direction="row" width="100%" height="360px">
        <ProductSlider
          datas={datas}
          loading={loading}
          component={ProductCards}
          product
        />
      </Stack>
    </HomeContainer>
  );
};

import { Stack } from "@mui/material";
import PromoHero from "../../../templates/promo/PromoHero";
import segampangitu from "./segampangitu.json";
import PromoSteps from "../../../templates/promo/PromoSteps";
import PromoChallenge from "../../../templates/promo/PromoChallenge";
import PromoImageText from "../../../organisms/promo/PromoImageText";
import PromoAbout from "../../../templates/promo/PromoAbout";
import PromoDownload from "../../../templates/promo/PromoDownload";
import PromoVideo from "../../../templates/promo/PromoVideo";
import { useRef } from "react";

export default function SegampangItu() {
  const aboutRef = useRef(null)
  const data = segampangitu;

  const scrollToAbout = () => {
    if (aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  return (
    <Stack>
      <PromoHero heroContent={data.hero} onReadMoreClick={scrollToAbout} />
      <PromoVideo videoContent={data.video.videoLink} />
      <PromoSteps stepsContent={data.steps} />
      <PromoChallenge challengeContent={data.challenge} />
      <PromoImageText
        buttonLink={data.challengeWithImg.buttonLink}
        buttonText={data.challengeWithImg.buttonText}
        heading={data.challengeWithImg.heading}
        image={data.challengeWithImg.image}
        subheading={data.challengeWithImg.subheading}
      />
      <PromoAbout sectionsData={data.about} ref={aboutRef} />
      <PromoDownload downloadContent={data.download} />
    </Stack>
  );
}

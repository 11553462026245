import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Pagination, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
import styles from "./hero-slider.module.css";
import "./hero-slider.module.css";
import { Box, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";

export default function HeroSlider({ promo }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const mobileStyleProps = {
    justifyContent: "center",
  };

  return (
    <Swiper
      style={{
        "--swiper-pagination-color": "#FFFF",
        "--swiper-pagination-bullet-width": "24px",
        "--swiper-pagination-bullet-height": "0px",
      }}
      spaceBetween={30}
      centeredSlides={true}
      autoplay={{
        delay: 3000,
        disableOnInteraction: false,
      }}
      pagination={{
        clickable: true,
        // el: paginationRef.current
      }}
      // navigation={true}
      modules={[Autoplay, Pagination, Navigation]}
      className={styles.swiper}
    >
      {promo?.map((item) => (
        <SwiperSlide
          key={item.attributes.promo_slug}
          className={styles.swiperSlide}
          style={isMobile ? mobileStyleProps : null}
        >
          <Link to={"/segampangitu"}>
            <Box
              component="img"
              src={
                item.attributes.promo_image_mobile.data.attributes.url
              }
              sx={{
                height: "100%",
                width: "100%",
              }}
            />
          </Link>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}

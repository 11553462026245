import { createTheme } from "@mui/material";

const theme = createTheme({
  typography: {
    fontFamily: "TT Commons",
    button: {
      textTransform: "none",
    },
  },

  palette: {
    primary: {
      main: "#03559A",
    },
    secondary: {
      main: "#088ADB",
    },
    vibrant: {
      main: "#1FBBEA",
      light: "#CFEDFF",
      contrastText: "#FFFFFF",
    },
    grey: {
      grey100: "#F5F5F5",
      grey200: "#EEEEEE",
      grey300: "#E0E0E0",
      grey400: "#BDBDBD",
      grey500: "#9E9E9E",
      grey600: "#757575",
      main: "#757575",
      grey700: "#616161",
      grey800: "#424242",
    },
    red: {
      main: "#FB5C59",
    },
    green: {
      main: "#40D6A0",
    },
  },

  components: {
    MuiTypography: {
      styleOverrides: {
        h1: ({ theme }) => ({
          fontSize: "64px",
          fontWeight: "800",
          color: theme.palette.primary.main,
          [theme.breakpoints.down("md")]: {
            fontSize: "40px",
          },
        }),
        h2: ({ theme }) => ({
          fontSize: "40px",
          fontWeight: "800",
          color: theme.palette.primary.main,
          [theme.breakpoints.down("md")]: {
            fontSize: "32px",
          },
        }),
        h3: ({ theme }) => ({
          fontSize: "32px",
          fontWeight: "800",
          color: theme.palette.primary.main,
          [theme.breakpoints.down("md")]: {
            fontSize: "24px",
          },
        }),
        h4: ({ theme }) => ({
          fontSize: "24px",
          fontWeight: "800",
          color: theme.palette.primary.main,
        }),
        h5: ({ theme }) => ({
          fontSize: "20px",
          fontWeight: "800",
          color: theme.palette.primary.main,
        }),
        body1: ({ theme }) => ({
          fontSize: "20px",
        }),
        body2: {
          fontSize: "16px",
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          textDecoration: "none",
        },
      },
    },
    MuiButton: {
      variants: [
        {
          props: { variant: "rounded" },
          style: {
            borderRadius: "200px",
            color: "#FFFFFF",
            backgroundColor: "#03559A",
          },
        },
      ],
    },
    MuiPaper: {
      styleOverrides: {
        elevation4: {
          boxShadow: "2px 2px 8px rgba(16, 24, 40, 0.1)",
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontFamily: "TT Commons",
        },
      },
    },
  },
});

export default theme;

import { Typography } from "@mui/material"

export const ButtonText = ({ children, sx }) => {

    return (
        <Typography
            variant="body2"
            textAlign="center"
            sx={{
                ...sx
            }}
        >
            {children}
        </Typography>
    )
}


import {
  Box,
  Stack,
  useMediaQuery,
  useTheme,
  Link,
  Drawer,
  IconButton,
  List,
} from "@mui/material";
import { ButtonPrimary } from "../atoms/Buttons";
import MenuRoundedIcon from "@mui/icons-material/MenuRounded";
import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import React, { useEffect } from "react";
import { Link as RouterLink } from "react-router-dom";
import { HPAMLogo } from "../../assets/graphic/logos";
import NavMenus from "../molecules/menu/NavMenus";
import configuration from "../../services/configuration.json";

export const Navbar = ({ promo }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  const [expand, setExpanded] = React.useState(false);
  const [activePromo, setActivePromo] = React.useState([]);
  const handleExpand = () => {
    setExpanded(!expand);
  };
  const pathname = window.location.pathname;

  React.useEffect(() => {
    const checkActivePromo = () => {
      const currentDate = new Date();
      const currentlyActive = promo.filter(
        (item) =>
          new Date(item?.attributes?.promo_start) < currentDate &&
          new Date(item?.attributes?.promo_end) > currentDate
      );

      setActivePromo(currentlyActive);
    };
    if (promo) {
      checkActivePromo();
    }
  }, [promo]);

  useEffect(() => {
    // Close drawer on route change
    if (isMobile) {
      setExpanded(false);
    }
  }, [pathname, isMobile]);

  const Navlinks = [
    {
      link: "#SegampangItu",
      href: "/segampangitu",
    },
    {
      link: "Promo",
      href: "/promo",
      submenu: activePromo,
    },
    {
      link: "Blog",
      href: "/blog",
    },
    {
      link: "Produk",
      href: "/produk",
    },
    // {
    //   link: "Event",
    //   href: "/event",
    // },
    {
      link: "Tentang Kami",
      href: "/tentang-kami",
    },
    {
      link: "FAQ",
      href: "https://faq.myhero.id/id/",
    },
  ];

  return (
    <Box
      component="nav"
      sx={{
        zIndex: "50",
        height: "80px",
        width: "100%",
        backgroundColor: expand ? "white" : "rgba(255,255,255, 0.8)",
        backdropFilter: "blur(16px)",
        position: "sticky",
        top: "0",
      }}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={{
          maxWidth: "1440px",
          margin: "0 auto",
          p: { md: "0 120px", xs: "0 24px" },
          height: "100%",
        }}
      >
        <Link
          component={RouterLink}
          to="/"
          onClick={isMobile & expand ? handleExpand : null}
        >
          <Box component="img" src={HPAMLogo} alt="HPAM Logo" height={26} />
        </Link>
        {isMobile ? (
          <Stack>
            <IconButton onClick={handleExpand}>
              {!expand ? (
                <MenuRoundedIcon fontSize="medium" color="primary" />
              ) : (
                <CloseRoundedIcon fontSize="medium" color="primary" />
              )}
            </IconButton>
            <Drawer
              open={expand}
              onClose={handleExpand}
              anchor="top"
              disableScrollLock={true}
              PaperProps={{
                sx: {
                  pt: 2,
                },
              }}
            >
              <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                px={2}
                pb={1}
              >
                <Box
                  component="img"
                  src={HPAMLogo}
                  alt="HPAM Logo"
                  height={26}
                />
                <IconButton onClick={handleExpand} size="small" sx={{ p: 0 }}>
                  <CloseRoundedIcon fontSize="medium" color="primary" />
                </IconButton>
              </Stack>
              <List>
                <NavMenus Navlinks={Navlinks} activePromo={activePromo} />
              </List>
            </Drawer>
          </Stack>
        ) : (
          <Stack
            direction="row"
            alignItems="center"
            sx={{
              gap: "24px",
            }}
          >
            <Stack
              direction="row"
              sx={{
                gap: "8px",
              }}
            >
              <NavMenus Navlinks={Navlinks} activePromo={activePromo} />
            </Stack>
            <ButtonPrimary hrefEx={configuration.home.redirect_link}>
              Mulai
            </ButtonPrimary>
          </Stack>
        )}
      </Stack>
    </Box>
  );
};

import { Typography } from "@mui/material";
import React from "react";
import BigTextPageWrapper from "../../molecules/BigTextPageWrapper";

const ErrorPage = () => {
  React.useEffect(() => {
    setTimeout(() => {
      window.location.href = "/";
    }, 5000);
  }, []);

  return (
    <BigTextPageWrapper>
      <Typography variant="h1" fontSize={100} lineHeight={0.75}>
        404
      </Typography>
      <Typography variant="h2" mb={2}>
        Not Found
      </Typography>
      <Typography variant="body1" fontWeight="bold">
        Halaman yang anda tujui tidak ditemukan :(
      </Typography>
    </BigTextPageWrapper>
  );
};

export default ErrorPage;

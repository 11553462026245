import { Box, Stack, Typography, useMediaQuery, useTheme } from "@mui/material"
import { Bareksa, Bloomberg, Infovesta, Kontan } from "../../../assets/graphic/logos"
import { HomeContainer } from "../../organisms/HomeContainer"

const mediaDatas = [Bareksa, Kontan, Bloomberg, Infovesta]

export const MediaSection = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("lg"))
    return (
        <Stack alignItems="center"
            width="100%"
            sx={{ bgcolor: "grey.grey100" }}>
            <HomeContainer
            >
                <Typography
                    variant="h4"
                >
                    <Typography component="span" variant="h4" sx={{ color: "vibrant.main" }}>MyHero</Typography> Telah Diliput Oleh
                </Typography>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent={isMobile ? "center" : "space-between"}
                    sx={{
                        p: isMobile ? "0px 16px" : "0px 128px",
                        width: "100%",
                        flexWrap: "wrap",
                        gap: "32px"
                    }}
                >
                    {mediaDatas.map((data,idx) => (
                        <Box key={idx} component="img" src={data} width="auto" height="40px"
                        />
                    ))}
                </Stack>
            </HomeContainer>
        </Stack>

    )
}
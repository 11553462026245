import { useTheme } from "@mui/material";
import React from "react";
import VerificationCard from "../../organisms/VerificationCard";

export default function SucessPage({subtitle}) {
    const theme = useTheme()
    React.useEffect(() => {
      setTimeout(() => {
        window.location.href = "/";
      }, 5000);
    }, []);

    return (
      <VerificationCard
        title="Success!"
        titleColor={theme.palette.green.main}
        subtitle={subtitle}
      />
    );
}

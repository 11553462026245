import { Box } from "@mui/material";
import { ReactComponent as LogoUtamaHPAMW } from "./Logo_Utama_W.svg";

export const Logo_Utama_W = () => {
  return (
    <Box>
      <LogoUtamaHPAMW
        style={{
          display: "inline-block",
          height: "42px",
          width: "auto",
        }}
      />
    </Box>
  );
};

export const HPAMLogo = require("./Logo_Utama_HPAM.svg").default;
export const MyHeroLogo = require("./myhero-logo.svg").default;

export const AppStore = require("./app-store.png");
export const PlayStore = require("./google-play-badge.png");
export const Kominfo = require("./kominfo.png");
export const Ojk = require("./ojk.png");
export const Bareksa = require("./bareksa.png");
export const Kontan = require("./kontan.png");
export const Bloomberg = require("./bloomberg.png");
export const Infovesta = require("./infovesta.png");

import { Button, Stack, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { engine, apiUrls } from "../../../services/apiService";
import { HomeContainer } from "../../organisms/HomeContainer";
import { BlogPostHeader } from "../../templates/BlogPostHeader";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";

import "./BlogPost.css";
import { grey } from "@mui/material/colors";

export const BlogPost = () => {
  let { slug } = useParams();
  const [postData, setPostData] = useState({});

  useEffect(() => {
    getBlogPostData(slug);
  }, []);

  async function getBlogPostData(slug) {
    try {
      const res = await engine.get(
        apiUrls?.blog?.getBlogBySlug.replace("${slug}", slug)
      );
      const resData = res.data;
      setPostData(resData.data[0].attributes);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <HomeContainer>
      <Stack
        direction="column"
        sx={{
          gap: "32px",
          width: "100%",
        }}
      >
        <Link to="/blog" style={{ textDecoration: "none" }}>
          <Button startIcon={<ArrowBackIosNewRoundedIcon />}>
            <Typography>Blog Home</Typography>
          </Button>
        </Link>
        <BlogPostHeader data={postData} />
        <Box
          component="img"
          src={postData?.image?.data?.attributes.url}
          sx={{
            objectFit: "cover",
            maxHeight: "400px",
            height: "auto",
            width: "auto",
          }}
        />
        <Box className="override-blogpost">
          <div dangerouslySetInnerHTML={{ __html: postData?.content }} />
        </Box>
      </Stack>
    </HomeContainer>
  );
};

import { Route, Routes } from "react-router-dom";
import { CssBaseline, Stack, ThemeProvider } from "@mui/material";
import { Navbar } from "./components/organisms/Navbar";
import theme from "./Theme";
import { Home } from "./components/pages/Home";
import { Footer } from "./components/templates/Footer";
import { Blog } from "./components/pages/blog";
import { BlogPost } from "./components/pages/blog/BlogPost";
import ScrollToTop from "./services/ScrollToTop";
import Produk from "./components/pages/produk";
import ProdukDetail from "./components/pages/produk/ProdukDetail";
import TentangKami from "./components/pages/tentang-kami";
import Promo from "./components/pages/promo";
import ErrorPage from "./components/pages/error";
import React from "react";
import { apiUrls, engine } from "./services/apiService";
import SegampangItu from "./components/pages/promo/segampangitu";
import { useQuery } from "@tanstack/react-query";
import Shortlink from "./components/pages/shortlink";
import SuccessPage from "./components/pages/email/SuccessPage";
import FailedPage from "./components/pages/email/FailedPage";
import DownloadApp from "./components/organisms/DownloadApp";
import DownloadPage from "./components/pages/download";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const routes = [
  { path: "/", element: <Home />, showFooterDisclaimer: false },
  { path: "/blog", element: <Blog />, showFooterDisclaimer: false },
  { path: "/blog/:slug", element: <BlogPost />, showFooterDisclaimer: false },
  { path: "/produk", element: <Produk />, showFooterDisclaimer: false },
  {
    path: "/produk/:id",
    element: <ProdukDetail />,
    showFooterDisclaimer: false,
  },
  {
    path: "/tentang-kami",
    element: <TentangKami />,
    showFooterDisclaimer: false,
  },
  { path: "/promo/:slug", element: <Promo />, showFooterDisclaimer: false },
  {
    path: "/segampangitu",
    element: <SegampangItu />,
    showFooterDisclaimer: false,
  },
  {
    path: "/download",
    element: <DownloadPage />,
    showFooterDisclaimer: false,
  },
  { path: "/404", element: <ErrorPage /> },
  { path: "/:shortlink", element: <Shortlink />, showFooterDisclaimer: false },
  { path: "*", element: <ErrorPage /> },
  {
    path: "/verifikasi/email/berhasil",
    element: <SuccessPage subtitle={"Email berhasil diverifikasi"} />,
  },
  {
    path: "/verifikasi/email/gagal",
    element: <FailedPage subtitle={"Email gagal diverifikasi."} />,
  },
  {
    path: "/verifikasi/revisi_data_nasabah/berhasil",
    element: <SuccessPage subtitle={"Perbaikan data kamu sudah berhasil"} />,
  },
  {
    path: "/verifikasi/revisi_data_nasabah/gagal",
    element: <FailedPage subtitle={"Link perbaikan data kamu sudah expired"} />,
  },
];

function App() {
  const { data: promoData } = useQuery({
    queryKey: ["promoData"],
    queryFn: async () => {
      const res = await engine.get(apiUrls.promo.getPromos);

      const resData = res?.data?.data;

      return resData;
    },
  });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Routes>
        {routes?.map((route, index) => (
          <Route
            key={index}
            path={route?.path}
            element={
              <ScrollToTop>
                <Stack height="100%">
                  <ToastContainer theme="colored" position="top-right" />
                  <Navbar promo={promoData} />
                  {route?.element}
                  <DownloadApp />
                  <Footer showDisclaimer={route?.showFooterDisclaimer} />
                </Stack>
              </ScrollToTop>
            }
          />
        ))}
      </Routes>
    </ThemeProvider>
  );
}

export default App;

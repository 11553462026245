import { Box, Link, Stack, Typography, useMediaQuery } from "@mui/material"
import { AppStore, Kominfo, Logo_Utama_W as LogoUtamaW, Ojk, PlayStore } from "../../assets/graphic/logos"
import { IconWrapper, InstagramLogo, LinkedinLogo, YoutubeLogo, TiktokLogo } from "../atoms/Icons"
import { HomeContainer } from "../organisms/HomeContainer"
import LocationOnRoundedIcon from '@mui/icons-material/LocationOnRounded';
import { MailRounded } from "@mui/icons-material"
import { FooterLinks } from "../organisms/FooterLinks"
import { useTheme } from "@emotion/react"

const footerLinks1 = [
    {
        href: "/",
        text: "Promo",
    },
    {
        href: "/produk",
        text: "Produk",
    },
    // {
    //     href: "/",
    //     text: "Event",
    // },
    {
        href: "/segampangitu",
        text: "#SegampangItu",
    },
]
const footerLinks2 = [
    {
        href: "https://faq.myhero.id/id/",
        text: "FAQ",
    },
    {
        href: "https://api.whatsapp.com/send/?phone=6287882525775",
        text: "Hubungi Kami",
    },
    {
        href: "https://faq.myhero.id/id/article/syarat-ketentuan-kebijakan-privasi-6o76k7/",
        text: "Syarat & Ketentuan",
    },
    {
        href: "https://faq.myhero.id/id/article/syarat-ketentuan-kebijakan-privasi-6o76k7/",
        text: "Kebijakan Privasi",
    },
]

export const Footer = () => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    return (
        <Box
            bgcolor="primary.main"
        >
            <HomeContainer
                sx={{
                    width: "100%",
                    gap: "32px",
                }}
            >
                <Stack
                    justifyContent="space-between"
                    direction={isMobile ? "column" : "row"}
                    alignItems="start"
                    width="100%"
                    sx={{
                        pb: "32px",
                        borderBottom: "2px solid rgba(255, 255, 255, 0.2)",
                        gap: "32px"
                    }}
                >
                    <LogoUtamaW />
                    <Stack
                        direction="row"
                        sx={{
                            gap: "16px",
                            flexWrap: "wrap",
                        }}
                    >
                        <IconWrapper icon={<TiktokLogo />} href="https://www.tiktok.com/@hpam_id" />
                        <IconWrapper icon={<InstagramLogo />} href="https://www.instagram.com/hpam.id/" />
                        <IconWrapper icon={<LinkedinLogo />} href="https://www.linkedin.com/company/henanassetmanagement" />
                        <IconWrapper icon={<YoutubeLogo />} href="https://www.youtube.com/@hpam_id" />
                    </Stack>
                </Stack>
                <Stack
                    justifyContent={isMobile ? "start" : "space-between"}
                    direction="row"
                    width="100%"
                    flexWrap="wrap"
                    sx={{ gap: "32px" }}
                >
                    <Stack
                        maxWidth={340}
                        sx={{
                            gap: "16px",
                        }}
                    >
                        <Typography variant="h5"
                            sx={{
                                color: "white",
                            }}
                        >
                            PT Henan Putihrai Asset Management
                        </Typography>
                        <Stack direction="row" sx={{ color: "#FFFFFF", gap: "20px" }}>
                            <LocationOnRoundedIcon />
                            <Typography variant="body2">Sahid Sudirman Center, 46th Floor <br />
                                Jl. Jend Sudirman No. 86, Jakarta</Typography>
                        </Stack>
                        <Stack direction="row" sx={{ color: "#FFFFFF", gap: "20px" }}>
                            <MailRounded />
                            <Typography variant="body2">cs@hpam.co.id</Typography>
                        </Stack>
                    </Stack>
                    <FooterLinks title="Produk" datas={footerLinks1} />
                    <FooterLinks title="Bantuan" datas={footerLinks2} />
                    <Stack sx={{ gap: "16px" }}>
                        <Typography variant="h5"
                            sx={{
                                color: "white",
                            }}
                        >
                            Download Aplikasi
                        </Typography>
                        <Stack
                            direction="row"
                            sx={{
                                display: "flex",
                                gap: "12px",
                                flexWrap: "wrap",
                            }}
                        >
                            <Link href={"https://play.google.com/store/apps/details?id=com.hpam.myhero"}>
                                <Box
                                    component="img"
                                    src={PlayStore}
                                    width="auto"
                                    height={40}
                                    sx={{
                                        display: "inline-block",
                                    }}
                                />
                            </Link>
                            <Link href={"https://apps.apple.com/id/app/myhero-hpam/id1499297292"}>
                                <Box
                                    component="img"
                                    src={AppStore}
                                    width="auto"
                                    height={40}
                                    sx={{
                                        display: "inline-block",

                                    }}
                                />
                            </Link>
                        </Stack>
                        <Stack
                            direction="row"
                            sx={{
                                gap: "32px",
                                p: "8px 16px",
                                bgcolor: "#ffffff",
                                borderRadius: "8px",
                                flexWrap: "wrap",
                            }}
                        >
                            <Stack gap={1}>
                                <Box
                                    component="img"
                                    src={Ojk}
                                    width={85.5}
                                    height={36}
                                    sx={{
                                        maxWidth: "85px",
                                        width: "auto",
                                        objectFit: "contain",
                                    }}
                                />
                                <Link href="https://reksadana.ojk.go.id/Public/ManajerInvestasiPublic.aspx?id=HP002">
                                    <Typography variant="body2" sx={{ fontSize: "12px", color: "#9E9E9E" }}>
                                        KEP-04/BL/MI/2006
                                    </Typography>
                                </Link>
                            </Stack>
                            <Stack gap={1}>
                                <Box
                                    component="img"
                                    src={Kominfo}
                                    height={36}
                                    sx={{
                                        maxWidth: "116px",
                                        width: "auto",
                                        objectFit: "contain",
                                    }}  
                                />
                                <Link href="/">
                                    <Typography variant="body2" sx={{ fontSize: "12px", color: "#9E9E9E" }}>
                                        00843/DJAI.PSE/04/2018
                                    </Typography>
                                </Link>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </HomeContainer>
        </Box >
    )
}
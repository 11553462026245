import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";
import downloadPage from "./downloadPage.json";
import { Controller, useForm } from "react-hook-form";
import { apiUrls, engine } from "../../../services/apiService";
import { toast } from "react-toastify";

export default function DownloadPage() {
  const { heading, subheading, src, downloadLink } = downloadPage;

  const { control, handleSubmit, formState } = useForm({
    defaultValues: {
      name: "",
      email: "",
    },
  });

  async function onSubmit(data) {
    const payload = {
      ...data,
      webinar: 4,
      phoneNumber: "-",
    };

    toast.promise(
      engine.post(apiUrls.download.participant, {
        data: payload,
      }),
      {
        pending: "Tunggu sebentar...",
        success: {
          autoClose: 500,
          render(c) {

            setTimeout(() => {
              window.location.href = downloadLink;
            }, 500);

            return "Cool! 3..2..1.. Downloading!";
          },
        },
        error: {
          render(data) {
            if(data.data.response.status === 403) {
                return "Oops, email kamu sudah terdaftar";
            }

            const error = data?.data?.error;

            return error.message ?? "Oops, ada yang salah nih";
          },
        },
      }
    );
  }

  const errors = formState.errors;

  return (
    <Stack
      sx={{
        width: "100%",
        height: "100%",
        bgcolor: "primary.main",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <HomeContainer
        sx={{
          p: { md: "32px 120px", xs: "24px 16px" },
          zIndex: 2,
        }}
      >
        <Stack
          direction={{ xs: "column-reverse", lg: "row" }}
          justifyContent="space-between"
          alignItems={{ xs: "left", lg: "center" }}
          color="#FFFFFF"
          width="100%"
          flexGrow={1}
        >
          <Stack>
            <Typography fontSize={{ xs: 20, lg: 24 }} mb={2} fontWeight={300}>
              {subheading}
            </Typography>
            <Typography
              fontSize={{
                xs: 32,
                lg: 52,
              }}
              fontWeight={800}
              lineHeight={1.2}
              color="#FFFFFF"
            >
              {heading}
            </Typography>
            <Stack
              component="form"
              onSubmit={handleSubmit(onSubmit)}
              sx={{ gap: 3, mt: 4, mb: 8 }}
              width={{ xs: "100%", lg: "auto" }}
            >
              <Stack
                sx={{
                  position: "relative",
                }}
              >
                <Controller
                  name="name"
                  control={control}
                  rules={{ required: "Nama tidak boleh kosong" }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Siapa namamu?"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "#fff",
                          border: "none",
                          borderRadius: 2,
                        },
                      }}
                    />
                  )}
                />
                <Typography
                  color="error.light"
                  fontSize={14}
                  fontWeight={700}
                  sx={{ position: "absolute", bottom: -24, left: 0 }}
                >
                  {errors.name?.message}
                </Typography>
              </Stack>
              <Stack
                sx={{
                  position: "relative",
                }}
              >
                <Controller
                  name="email"
                  control={control}
                  rules={{
                    required: "Email tidak boleh kosong",
                    pattern: {
                      value: /\S+@\S+\.\S+/,
                      message: "Email tidak valid",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      placeholder="Bagi emailmu dong"
                      variant="outlined"
                      sx={{
                        "& .MuiOutlinedInput-root": {
                          bgcolor: "#fff",
                          border: "none",
                          borderRadius: 2,
                        },
                      }}
                    />
                  )}
                />
                <Typography
                  color="error.light"
                  fontSize={14}
                  fontWeight={700}
                  sx={{ position: "absolute", bottom: -24 }}
                >
                  {errors.email?.message}
                </Typography>
              </Stack>
              <Button
                type="submit"
                onClick={handleSubmit(onSubmit)}
                disabled={formState.isSubmitting}
                variant="contained"
                disableElevation
                color="vibrant"
                size="large"
                sx={{
                  mt: 4,
                  borderRadius: 2,
                  width: "fit-content",
                }}
              >
                Download Sekarang
              </Button>
            </Stack>
          </Stack>
          <Box
            component="img"
            src={src}
            alt="hiro"
            sx={{
              height: "auto",
              width: "100%",
              maxWidth: 572,
            }}
          />
        </Stack>
      </HomeContainer>
      <Box
        sx={{
          position: "absolute",
          flexShrink: 0,
          left: "-40%",
          top: { xs: "75%", lg: "50%" },
          transform: "translate(0, -50%)",
          height: { xs: "100%", lg: "200%" },
          width: "100%",
          zIndex: 1,
          borderRadius: "50%",
          background: `linear-gradient(214deg, rgba(82, 193, 225, 0.05) 31.81%, rgba(87, 205, 238, 0.30) 83.45%)`,
        }}
      />
    </Stack>
  );
}

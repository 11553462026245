import { Stack } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getProdukData } from "../../redux/produkDataSlice";
import { BenefitSection } from "../templates/home/BenefitSection";
import { FaqSection } from "../templates/home/FaqSection";
import { HeroSection } from "../templates/home/HeroSection";
import { MediaSection } from "../templates/home/MediaSection";
import { ProductSection } from "../templates/home/ProductSection";
import SimulasiSection from "../templates/home/SimulasiSection";
import { apiUrls, engine } from "../../services/apiService";

export const Home = () => {
  const dispatch = useDispatch();
  const produkData = useSelector((state) => state.produkData.produkData);
  const loading = useSelector((state) => state.produkData.loading);
  const [promoData, setPromoData] = React.useState([]);
  const [activePromo, setActivePromo] = React.useState([]);

  const links = {
    playStore: "https://play.google.com/store/apps/details?id=com.hpam.myhero",
    appStore: "https://apps.apple.com/id/app/myhero-hpam/id1499297292"
  };

  React.useEffect(() => {
    dispatch(getProdukData());
    const getPromoData = async () => {
      const data = await engine.get(
        apiUrls.promo.getPromos +
          "&populate[0]=promo_image&populate[1]=promo_image_mobile"
      );
      setPromoData(data?.data?.data);
    };

    getPromoData();
  }, []);

  React.useEffect(() => {
    const checkActivePromo = () => {
      const currentDate = new Date();
      const currentlyActive = promoData.filter(
        (item) =>
          new Date(item?.attributes?.promo_start) < currentDate &&
          new Date(item?.attributes?.promo_end) > currentDate
      );

      setActivePromo(currentlyActive);
    };
    if (promoData) {
      checkActivePromo();
    }
  }, [promoData]);

  return (
    <Stack alignItems="center">
      <HeroSection promo={activePromo} applink = {links} />
      <MediaSection />
      <BenefitSection />
      <ProductSection datas={produkData} loading={loading} />
      <SimulasiSection datas={produkData} />
      <FaqSection />
    </Stack>
  );
};

const separateCategory = ( blogsData ) => {
    if (!blogsData) return console.log("no data");
    let newTypes = [];
    blogsData.map((item) => {
        if (item.attributes.type !== null) {
            if (!newTypes[item.attributes.type]) {
                newTypes[item.attributes.type] = { category: item.attributes.type, blogs: [item] };
            } else {
                newTypes[item.attributes.type].blogs.push(item);
            }
        }
    });
    return Object.values(newTypes);
};

export default separateCategory;
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import useMediaQuery from "@mui/material/useMediaQuery";
import { EastRounded } from "@mui/icons-material";

const TentangKamiTitle = () => {
    const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"));

    return (
        <Stack>
            <Stack
                gap={2}
                alignItems="flex-start"
                maxWidth={isMobile ? null : "575px"}
            >
                <Typography
                    variant="h1"
                    color="primary"
                >
                    Tentang Kami
                </Typography>
                <Typography
                    variant="body2"
                >
                    <b>PT Henan Putihrai Asset Management (Henan Asset)</b> adalah Pelaku Usaha Jasa Keuangan yang bergerak di industri pasar modal. Didirikan sejak tahun 2006, Henan Asset menjadi perusahaan spin-off dari PT Henan Putihrai Sekuritas yang telah memiliki izin usaha Manajer Investasi.
                </Typography>
                <Link
                    component="a"
                    href="https://hpam.co.id/"
                    sx={{
                        color: "primary.main",
                        cursor: "pointer"
                    }}
                >
                    <Stack
                        direction="row"
                        alignItems="center"
                        gap={1}
                        mt={1}
                    >
                        <Typography
                            variant="body2"
                        >
                            Lebih lanjut mengenai Henan Asset
                        </Typography>
                        <EastRounded />
                    </Stack>
                </Link>
            </Stack>
        </Stack>
    )
}

export default TentangKamiTitle
import { Stack, Typography } from "@mui/material";
import { ButtonCategory } from "../atoms/Buttons";

const PromoButtonBar = ({ datas }) => {
  return (
    <Stack
      zIndex={100}
      direction="row"
      width="100%"
      py={2}
      px={2}
      alignItems="center"
      justifyContent={{ xs: "flex-start", sm: "center" }}
      position="sticky"
      overflow="scroll"
      gap={1}
      bgcolor="rgba(255,255,255, 0.8)"
      top={80}
      sx={{
        backdropFilter: "blur(16px)",
        "&::-webkit-scrollbar": {
          display: "none",
        },
        scrollbarWidth: "none",
        msOverflowStyle: "none",
      }}
    >
      {datas?.map((data) => (
        <ButtonCategory key={data.id} scroll href={data.title}>
          <Typography variant="body1">{data.title}</Typography>
        </ButtonCategory>
      ))}
    </Stack>
  );
};

export default PromoButtonBar;

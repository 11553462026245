import { configureStore } from '@reduxjs/toolkit'
import produkDataReducer from './produkDataSlice'
import produkNavReducer from './produkNavSlice'
import aboutUsDataReducer from './aboutUsDataSlice'
import promoDataReducer from './promoDataSlice'

export const store = configureStore({
  reducer: {
    produkData : produkDataReducer,
    produkNav : produkNavReducer,
    aboutUsData : aboutUsDataReducer,
    promoData : promoDataReducer,
  },
})
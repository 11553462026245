import { Typography } from "@mui/material";
import BigTextPageWrapper from "../molecules/BigTextPageWrapper";

export default function VerificationCard({ title, titleColor, subtitle }) {
  return (
    <BigTextPageWrapper>
      <Typography variant="h1" fontSize={100} sx={{ color: titleColor }}>
        {title}
      </Typography>
      <Typography variant="h3" fontWeight="normal" color="black">
        {subtitle}
      </Typography>
    </BigTextPageWrapper>
  );
}

export const AppHome = "https://res.cloudinary.com/dtoa2e43y/image/upload/v1705769969/myhero-investasi-reksadana.png"
export const Berhasil = require("./berhasil.png")
export const Kantong = require("./kantong.png")
export const Menabung = require("./menabung.png")
export const Saly = require("./saly.png")
export const Slider = require("./slider1.png")
export const MoneyMarket = require("./moneymarket.png")
export const Plus = require("./plus.png")
export const Syariah = require("./syariah.png")
export const Ultima = require("./ultima.png")
export const TentangKamiimg = require("./tentangkami.png")
import Typography from '@mui/material/Typography'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import Stack from '@mui/material/Stack'

const NilaiKamiCards = ({ datas }) => {
    const Component = datas.icon

    return (
        <Card
            elevation={4}
            sx={{
                cursor: "grab",
                height: "210px",
                my: 2,
            }}
        >
            <CardContent
                sx={{ maxWidth: "364px", height: "100%", }}
            >
                <Stack
                    gap={2}
                    sx={{ height: "100%", }}
                >
                    <Component
                        color="vibrant"
                        sx={{ fontSize: "56px" }}
                    />
                    <Stack
                        sx={{ height: "100%", }}
                    >
                        <Typography
                            variant="body1"
                            sx={{ fontWeight: "800" }}
                        >
                            {datas.title}
                        </Typography>
                        <Typography variant="body2" sx={{ fontSize: "16px" }}>
                            {datas.desc}
                        </Typography>
                    </Stack>
                </Stack>
            </CardContent>
        </Card>
    )
}

export default NilaiKamiCards
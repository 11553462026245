import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'

const NumbersItems = ({ item }) => {
    return (
        <Stack
            alignItems="center"
            py={2}
        >
            <Typography
                variant="h1"
                color="white"
                fontWeight={800}
                lineHeight={1}
            >
                {item.number}
            </Typography>
            <Typography
                variant="body1"
                color="white"
                textAlign="center"
                fontSize={{ xs: "16px", md: "20px"}}
                whiteSpace="nowrap"
                lineHeight={1}
            >
                {item.title}
            </Typography>
        </Stack>
    )
}

export default NumbersItems
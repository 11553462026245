import { Paper, Stack, Typography, Avatar, Card } from "@mui/material";
import { useEffect, useState } from "react";
import ProductChart from "../molecules/charts/ProductChart";
import formatNumber from "../../services/formatNumber";
import useProfitLoss from "../../services/useProfitLoss";
import { api, apiUrls } from "../../services/apiService";
import { useNavigate } from "react-router-dom";
import ProductCardHeader from "../molecules/ProductCardHeader";
import ProductCardFooter from "../molecules/ProductCardFooter";

export const ProductCards = ({ datas }) => {
  const [profitLoss, handleProfitLoss] = useProfitLoss(0);
  const [chartData, setChartData] = useState([]);
  const navigate = useNavigate();

  const getChartData = async (id) => {
    const res = await api.get(
      apiUrls?.produk?.getProdukNav.replace("${id}", id)
    );
    setChartData(res.data.data.kinerja);
  };
  useEffect(() => {
    getChartData(datas.id);
  }, []);

  useEffect(() => {
    handleProfitLoss(datas.kinerja_1Y);
  }, [datas]);

  return (
    <Card
      elevation={4}
      component={Stack}
      height="100%"
      maxHeight="320px"
      onClick={() => navigate(`/produk/${datas.id}`)}
      py={2}
      px={1.5}
      sx={{
        cursor: "pointer",
      }}
    >
      <Stack
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        flexGrow={1}
      >
        <ProductCardHeader data={datas} profitLoss={profitLoss} />
        <Stack width="100%" height="160px">
          <ProductChart small data={chartData} profitLoss={profitLoss} />
        </Stack>
        <ProductCardFooter profitLoss={profitLoss} data={datas} />
      </Stack>
    </Card>
  );
};

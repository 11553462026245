
function TitleFormatter({ input }) {
  const formattedTitle = input
    .split("_")
    .map(word => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");

  return formattedTitle;
}

export default TitleFormatter;


const FormatDate = (props) => {
  const newDate = new Date(props);
  const day = newDate.getDate();
  const month = newDate.toLocaleString("en-US", { month: "long" });
  const year = newDate.getFullYear();
  return `${day} ${month} ${year}`;
}

export default FormatDate;

import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import "swiper/css";
import "swiper/css/pagination";
import PromoSliderCard from "../../molecules/PromoSliderCard";
import { Pagination, Navigation } from "swiper";
import { Button, Stack, useMediaQuery } from "@mui/material";
import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";

export function PromoSlider({ slides, swiperRef }) {
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("lg"));

  const { prevRef, nextRef } = swiperRef;

  return (
    <div>
      <Swiper
        breakpoints={{
          1400: { slidesPerView: 2.8 },
          1200: { slidesPerView: 3 },
          1000: { slidesPerView: 3 },
          720: { slidesPerView: 2.8 },
          420: { slidesPerView: 1.8 },
          0: { slidesPerView: 1.2 },
        }}
        spaceBetween={16}
        navigation={{
          prevEl: prevRef.current,
          nextEl: nextRef.current,
          hiddenClass: "hidden",
        }}
        onBeforeInit={(swiper) => {
          swiperRef.prevRef.current = swiper.navigation?.prevEl;
          swiperRef.nextRef.current = swiper.navigation?.nextEl;
        }}
        pagination={{
          clickable: true,
        }}
        style={{
          padding: !isMobile ? "16px 0 24px 24px" : "16px 0 24px 0",
          width: "100%",
          height: "100%",
          "--swiper-navigation-size": "0",
          zIndex: 3,
        }}
        onSwiper={(swiper) => {
          swiperRef.current = swiper;
        }}
      >
        {slides.map((slide, index) => {
          return (
            <SwiperSlide
              key={index}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                height: "100%",
              }}
            >
              <PromoSliderCard src={slide.src} heading={slide.heading} />
            </SwiperSlide>
          );
        })}
      </Swiper>
      <SwiperNavButtons swiperRef={swiperRef} />
    </div>
  );
}

export function SwiperNavButtons({ swiperRef }) {
  const { prevRef, nextRef } = swiperRef || {};

  const buttonProps = {
    sx: {
      borderRadius: "50%",
      color: "primary.main",
      p: 0,
      width: 44,
      minWidth: 0,
      height: 44,
      bgcolor: "#FFFFFF",
      "&:hover": {
        bgcolor: "#FFFFFF",
      },
    },
    disableElevation: true,
    variant: "contained",
  };

  const handlePrevClick = () => {
    swiperRef?.current?.slidePrev();
  };

  const handleNextClick = () => {
    swiperRef?.current?.slideNext();
  };

  return (
    <Stack
      direction="row"
      gap={2}
      mt={4}
      sx={{
        position: "absolute",
        left: 0,
        bottom: { xs: "auto", lg: 72 },
        top: { xs: -84, lg: "auto" },
        display: { xs: "none", lg: "flex" },
      }}
    >
      <Button {...buttonProps} ref={prevRef} onClick={handlePrevClick}>
        <ArrowBackIosNewRoundedIcon />
      </Button>
      <Button {...buttonProps} ref={nextRef} onClick={handleNextClick}>
        <ArrowForwardIosRoundedIcon />
      </Button>
    </Stack>
  );
}

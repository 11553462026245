import { Stack, Typography } from "@mui/material"
import formatNumber from "../../services/formatNumber"


const ProdukSummaryItems = ({ item, title }) => {
    return (
        <Stack
            gap={0.5}
        >
            <Typography variant="body2" color="grey">
                {title}
            </Typography>
            <Typography variant="h3">
                {formatNumber(item) < 1000 ? formatNumber(item) + " %" : formatNumber(item)}
            </Typography>
        </Stack>
    )
}

export default ProdukSummaryItems
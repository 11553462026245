import { Avatar, Stack, Typography } from "@mui/material";
import formatNumber from "../../services/formatNumber";

const ProductCardFooter = ({ data, profitLoss }) => {
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      alignItems="flex-end"
      sx={{
        width: "100%",
      }}
    >
      {/* <Avatar
        src={`/${data.kode_produk}.png`}
        sx={{
          width: 56,
          height: 56,
          fontSize: "24px",
        }}
      >
        HP
      </Avatar> */}
      <Stack
        direction="column"
        alignItems="flex-start"
        sx={{
          gap: "2px",
        }}
      >
        <Typography
          fontSize={14}
          sx={{
            color: "grey.grey600",
            textAlign: "right",
          }}
        >
          NAV
        </Typography>
        <Typography
          variant="h4"
          sx={{
            color: "primary.main",
          }}
        >
          {formatNumber(data.nav)}
        </Typography>
      </Stack>
      <Stack direction="column" alignItems="flex-end">
        <Typography
          fontSize={14}
          noWrap
          sx={{
            color: "grey.grey600",
            textAlign: "right",
          }}
        >
          Return 1Y
        </Typography>
        <Typography
          variant="h5"
          sx={{
            color: profitLoss ? "green.main" : "red.main",
            whiteSpace: "nowrap",
          }}
        >
          {formatNumber(data.kinerja_1Y)} %
        </Typography>
      </Stack>
    </Stack>
  );
};

export default ProductCardFooter;

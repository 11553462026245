import Box from "@mui/material/Box"
import Skeleton from "@mui/material/Skeleton"

const FullWidthBanner = ({ src, alt }) => {
    return (
        <Box
            minHeight="100%"
        >
            {src ?
                <Box
                    component="img"
                    src={src}
                    alt={alt}
                    width="100%"
                    height="auto"
                />
                :
                <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={600}
                />}
        </Box>
    )
}

export default FullWidthBanner
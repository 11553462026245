import { Avatar, Stack, Typography } from "@mui/material";

const ProductCardHeader = ({ data }) => {
  return (
    <Stack
      direction="row"
      alignItems="flex-start"
      justifyContent="space-between"
      gap={1.5}
      width="100%"
    >
      <Stack direction="column" alignItems="flex-start" width="100%">
        <Typography
          variant="body2"
          sx={{
            color: "grey.grey600",
            fontSize: "12px",
          }}
        >
          {data.keterangan_produk}
        </Typography>
        <Typography variant="h5" fontSize={18} lineHeight={1}>
          {data.nama_produk.replace("HPAM ", "")}
        </Typography>
      </Stack>
      <Avatar
        src={data.link_logo}
        sx={{
          width: 42,
          height: 42,
          fontSize: "20px",
        }}
      >
        HP
      </Avatar>
    </Stack>
  );
};

export default ProductCardHeader;

import { Box, IconButton, Input, InputAdornment, InputBase, OutlinedInput, Stack, TextField } from "@mui/material";
import SearchRoundedIcon from '@mui/icons-material/SearchRounded';
import { CloseRounded } from "@mui/icons-material";

export const SearchBar = ({ searchTerm, setSearchTerm, setSearch }) => {
    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const handleSearch = () => {
        setSearch(false)
        setSearchTerm("")
    }
    return (
        <Box component="form"
            width="100%"
            sx={{
                maxWidth: "400px",
            }}
            onSubmit={(e) => e.preventDefault()}
        >
            <OutlinedInput
                variant="outlined"
                placeholder="Kata kunci..."
                onChange={handleInputChange}
                value={searchTerm}
                type="text"
                sx={{
                    width: "100%",
                }}
                endAdornment={
                    <InputAdornment>
                        <IconButton
                            onClick={handleSearch}
                        >
                            <CloseRounded color="white" />
                        </IconButton>
                    </InputAdornment>
                } />
        </Box>
    )
}
import { HomeContainer } from "../../organisms/HomeContainer"
import Stack from "@mui/material/Stack"
import Typography from "@mui/material/Typography"
import NilaiKamiCards from "../../molecules/cards/NilaiKamiCards"
import ProductSlider from "../../molecules/sliders/ProductSlider"
import BalanceRounded from "@mui/icons-material/BalanceRounded"
import HandshakeOutlined from "@mui/icons-material/HandshakeOutlined"
import DiamondOutlinedIcon from '@mui/icons-material/DiamondOutlined';
import ShieldOutlinedIcon from '@mui/icons-material/ShieldOutlined';
import EmojiObjectsOutlinedIcon from '@mui/icons-material/EmojiObjectsOutlined';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';

const datas = [
    {
        icon: BalanceRounded,
        title: "Integritas",
        desc: "Berkomitmen untuk membangun hubungan jangka panjang dan produktif secara berkelanjutan",
    },
    {
        icon: HandshakeOutlined,
        title: "Service Excellence",
        desc: "Semangat dan paradigma konsisten yang berfokus pada klien dan berorientasi pada pelayanan terbaik",
    },
    {
        icon: DiamondOutlinedIcon,
        title: "Value Creation",
        desc: "Memahami kebutuhan klien dan memberikan solusi yang efektif",
    },
    {
        icon: ShieldOutlinedIcon,
        title: "Prudence",
        desc: "Poros pertumbuhan konservatif yang memupuk kebajikan dan nilai-nilai yang mendasar",
    },
    {
        icon: EmojiObjectsOutlinedIcon,
        title: "Inovasi",
        desc: "Fokus pada kompetensi dasar yang memberikan pengetahuan dan kemampuan mendalam",
    },
    {
        icon: VisibilityOutlinedIcon,
        title: "Transparansi",
        desc: "Model manajemen yang memupuk saling keterkaitan secara strategis",
    },
]

const NilaiKami = () => {
    return (
        <HomeContainer>
            <Stack
                alignItems="center"
                width="100%"
                height="100%"
                gap={4}
            >
                <Typography
                    variant="h2"
                >
                    Nilai-Nilai Kami
                </Typography>
                <Stack
                    width="100%"
                    height="100%"
                    maxHeight="240px"
                >
                    <ProductSlider
                        datas={datas}
                        component={NilaiKamiCards}
                    />
                </Stack>
            </Stack>
        </HomeContainer>
    )
}

export default NilaiKami
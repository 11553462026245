import { Avatar, Typography, useMediaQuery } from "@mui/material"
import { Stack } from "@mui/system"
import { useEffect, useMemo } from "react"
import { useSelector } from "react-redux"
import formatNumber from "../../../services/formatNumber"
import useProfitLoss from "../../../services/useProfitLoss"

const ProdukDetailHeader = ({ nav, data, loading, profitLoss }) => {
    const isMobile = useMediaQuery(theme => theme.breakpoints.down("md"))

    return (
        <Stack
            direction={isMobile ? "column" : "row"}
            justifyContent="space-between"
            alignItems="flex-start"
            width="100%"
        >
            <Stack
                direction="row"
                alignItems="center"
                width="100%"
                gap={isMobile ? 2 : 4}
            >
                <Avatar
                    src={data.link_logo}
                    sx={{ width: 64, height: 64 }}
                >
                    HP
                </Avatar>
                <Stack>
                    <Typography variant="h2">
                        {data.nama_produk}
                    </Typography>
                    <Typography variant="body1">
                        {data.keterangan_produk}
                    </Typography>
                </Stack>
            </Stack>
            <Stack py={0.5}
                alignItems="flex-end"
                width="100%"
            >
                <Typography variant="body2" color="grey" textAlign="right" noWrap>
                    Return 1Y
                </Typography>
                <Typography variant="h2"
                    sx={{
                        color: profitLoss ? "green.main" : "red.main",
                        whiteSpace: "nowrap"
                    }}>
                    {formatNumber(data.kinerja_1Y)} %
                </Typography>
            </Stack>
        </Stack>
    )
}

export default ProdukDetailHeader
import {
  Area,
  ComposedChart,
  Legend,
  Line,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { useTheme } from "@mui/material/styles";
import { useEffect, useState } from "react";
import Paper from "@mui/material/Paper";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import formatNumber from "../../../services/formatNumber";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import SimulasiChartLegend from "./SimulasiChartLegend";

const getIntersectionColor = (_intersection, isLast) => {
  if (isLast) {
    return _intersection.line1isHigherNext ? "#1FBAEB33" : "#1FBAEB33";
  }

  return _intersection.line1isHigher ? "#1FBAEB33" : "#1FBAEB33";
};

const CustomTooltip = ({ active, payload, label }) => {
  if (active && payload && payload.length) {
    return (
      <Paper
        elevation={0}
        variant="outlined"
        sx={{
          width: "240px",
        }}
      >
        <Stack p={1} width="100%" gap={2}>
          <Typography
            variant="body2"
            color="primary"
            fontWeight={600}
            textAlign="center"
          >
            {label}
          </Typography>
          <Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    bgcolor: "vibrant.main",
                    borderRadius: "50%",
                  }}
                />
                <Typography sx={{ fontSize: "12px" }}>MyHero</Typography>
              </Stack>
              <Typography sx={{ fontSize: "12px" }}>
                {formatNumber(payload[0].value[0])}
              </Typography>
            </Stack>
            <Stack
              direction="row"
              justifyContent="space-between"
              alignItems="center"
              width="100%"
            >
              <Stack direction="row" alignItems="center" gap={1}>
                <Box
                  sx={{
                    width: "12px",
                    height: "12px",
                    bgcolor: "primary.main",
                    borderRadius: "50%",
                  }}
                />
                <Typography sx={{ fontSize: "12px" }}>Nabung biasa</Typography>
              </Stack>
              <Typography sx={{ fontSize: "12px" }}>
                {formatNumber(payload[0].value[1])}
              </Typography>
            </Stack>
          </Stack>
        </Stack>
      </Paper>
    );
  }
  return null;
};

const FormattedLegend = (value) => {
  if (value === "pv") {
    return <span>MyHero</span>;
  } else {
    return <span>Nabung Biasa</span>;
  }
};

const SimulasiChart = ({ data }) => {
  const [value, setValue] = useState({
    min: 0,
    max: 0,
  });
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md"));

  const id = "colorPv";

  useEffect(() => {
    if (data) {
      const min = data[24]?.pv;
      const max = data[24]?.uv;

      setValue([min, max]);
    }
  }, [data]);

  // offset to only fill between two lines

  const dataWithRange = data.map((d) => ({
    ...d,
    range: d.pv !== undefined && d.uv !== undefined ? [d.pv, d.uv] : [],
  }));

  // need to find intersections as points where we to change fill color
  const intersections = data
    .map((d, i) =>
      intersect(
        i,
        d.pv,
        i + 1,
        data[i + 1]?.pv,
        i,
        d.uv,
        i + 1,
        data[i + 1]?.uv
      )
    )
    .filter((d) => d && !isNaN(d.x));

  // filtering out segments without intersections & duplicates (in case end current 2 segments are also
  // start of 2 next segments)
  const filteredIntersections = intersections.filter(
    (d, i) => i === intersections.length - 1 || d.x !== intersections[i - 1]?.x
  );

  return (
    <ResponsiveContainer width="100%" height={360}>
      <ComposedChart
        data={dataWithRange}
        margin={{
          left: isMobile ? 16 : 32,
          right: isMobile ? 16 : 32,
          top: 0,
          bottom: 32,
        }}
        style={{
          fontFamily: theme.typography.fontFamily,
        }}
      >
        <Legend
          verticalAlign="top"
          margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
          formatter={FormattedLegend}
          content={<SimulasiChartLegend maxValues={value} />}
        />
        <Tooltip
          content={<CustomTooltip />}
          cursor={{
            stroke: "#BABABA",
            strokeWidth: 1,
            strokeDasharray: "5 5",
          }}
        />
        <defs>
          <linearGradient id={id}>
            {filteredIntersections.length ? (
              filteredIntersections.map((intersection, i) => {
                const nextIntersection = filteredIntersections[i + 1];

                let closeColor = "";
                let startColor = "";

                const isLast = i === filteredIntersections.length - 1;

                if (isLast) {
                  closeColor = getIntersectionColor(intersection);
                  startColor = getIntersectionColor(intersection, true);
                } else {
                  closeColor = getIntersectionColor(intersection);
                  startColor = getIntersectionColor(nextIntersection);
                }

                const offset =
                  intersection.x /
                  (data.filter((d) => d.pv !== undefined && d.uv !== undefined)
                    .length -
                    1);

                return (
                  <>
                    <stop
                      offset={offset}
                      stopColor={closeColor}
                      stopOpacity={0.9}
                    />
                    <stop
                      offset={offset}
                      stopColor={startColor}
                      stopOpacity={0.9}
                    />
                  </>
                );
              })
            ) : (
              <stop
                offset={0}
                stopColor={data[0].pv > data[0].uv ? "#1FBAEB33" : "none"}
              />
            )}
          </linearGradient>
        </defs>
        <Area
          dataKey="range"
          stroke="#8884d8"
          strokeWidth={0}
          fill={`url(#${id})`}
        />
        <Line
          type="monotone"
          dot={false}
          dataKey="pv"
          stroke={theme.palette.vibrant.main}
          strokeWidth={2}
          activeDot={{ r: 8 }}
          fill={`url(#colorPv)`}
          stackId="1"
        />
        <Line
          type="monotone"
          dot={false}
          dataKey="uv"
          stroke={theme.palette.primary.main}
          strokeWidth={2}
          fill="none"
          activeDot={{ r: 8 }}
          stackId="2"
        />
        <XAxis
          dataKey="tahun"
          interval={isMobile ? "preserveEnd" : 5}
          tickLine={false}
          tickMargin={8}
          angle={0}
          allowDecimals={false}
          axisLine={false}
        />
        <YAxis
          dataKey="pv"
          type="number"
          domain={["auto", value[1] * 1.2]}
          orientation="right"
          tickLine={false}
          tickMargin={8}
          allowDecimals={false}
          axisLine={false}
          tickFormatter={(value) => {
            return formatNumber(value).toString().replace("Rp.", "");
          }}
          ticks={value}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};

function intersect(x1, y1, x2, y2, x3, y3, x4, y4) {
  // Check if none of the lines are of length 0
  if ((x1 === x2 && y1 === y2) || (x3 === x4 && y3 === y4)) {
    return false;
  }

  const denominator = (y4 - y3) * (x2 - x1) - (x4 - x3) * (y2 - y1);

  // Lines are parallel
  if (denominator === 0) {
    return false;
  }

  let ua = ((x4 - x3) * (y1 - y3) - (y4 - y3) * (x1 - x3)) / denominator;
  let ub = ((x2 - x1) * (y1 - y3) - (y2 - y1) * (x1 - x3)) / denominator;

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) {
    return false;
  }

  // Return a object with the x and y coordinates of the intersection
  let x = x1 + ua * (x2 - x1);
  let y = y1 + ua * (y2 - y1);

  const line1isHigher = y1 > y3;
  const line1isHigherNext = y2 > y4;

  return { x, y, line1isHigher, line1isHigherNext };
}

export default SimulasiChart;

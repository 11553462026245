import Grid from "@mui/material/Unstable_Grid2/Grid2";
import { HomeContainer } from "./HomeContainer";
import BlogCards from "../molecules/cards/BlogCards";

export const BlogGrid = ({ datas, searchTerm, categorySelect }) => {
  let filteredDatas = categorySelect
    ? datas.filter(
        (data) =>
          data.attributes.type === categorySelect &&
          data.attributes.content
            .toLowerCase()
            .includes(searchTerm.toLowerCase())
      )
    : datas.filter((data) =>
        data.attributes.content.toLowerCase().includes(searchTerm.toLowerCase())
      );

  return (
    <HomeContainer>
      <Grid
        container
        spacing={4}
        rowSpacing={8}
        width="100%"
        justifyContent="center"
      >
        {filteredDatas.map((data, idx) => (
          <Grid
            key={idx}
            lg={4}
            sm={6}
            xs={12}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            <BlogCards datas={data} />
          </Grid>
        ))}
      </Grid>
    </HomeContainer>
  );
};

import { Stack, Typography } from "@mui/material";
import PromoImageText from "../../organisms/promo/PromoImageText";
import { HomeContainer } from "../../organisms/HomeContainer";
import { forwardRef } from "react";

const PromoAbout = forwardRef(({ sectionsData }, ref) => {
  return (
    <Stack mt={8} ref={ref}>
      <HomeContainer
        sx={{
          p: { md: "0px 120px", xs: "0px 16px" },
        }}
      >
        <Typography
          variant="h2"
          fontWeight={600}
          color="#000000"
          fontSize={{ xs: 32, lg: 40 }}
          mr="auto"
          
        >
          {sectionsData.heading}
        </Typography>
      </HomeContainer>
      {sectionsData.sections.map((sectionData, index) => {
        return (
          <PromoImageText
            key={index}
            heading={sectionData.heading}
            subheading={sectionData.subheading}
            image={sectionData.image}
            reverse={index % 2 === 0}
          />
        );
      })}
    </Stack>
  );
});

export default PromoAbout;
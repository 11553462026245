import { Stack } from "@mui/system"
import { HomeAccordion } from "../../molecules/HomeAccordion"
import { HomeHeadings } from "../../molecules/HomeHeadings"
import { HomeContainer } from "../../organisms/HomeContainer"
import { Typography } from "@mui/material"

const FaqDatas = [
    {
        title: "Apa itu MyHero?",
        body: "MyHero adalah sebuah aplikasi investasi online yang diluncurkan sejak awal tahun 2020 di bawah kelolaan PT. Henan Putihrai Asset Management (Henan Asset)."
    },
    {
        title: "Apakah keunggulan investasi di MyHero?",
        body: "Investasi di MyHero mudah, terjangkau, aman, profitabel, gratis biaya komisi, bebas pajak, bebas biaya pencairan, dan terpercaya. PT Henan Putihrai didirikan sebagai perusahaan perantara pedagang efek dan merupakan anggota pendiri Bursa Efek Jakarta (BEJ) sejak tahun 1990. Investasi di MyHero cocok bagi pemula dan dapat disesuaikan dengan tujuan keuangan investor."
    },
    {
        title: "Apa itu Reksa Dana?",
        body: "Reksa dana adalah paket investasi yang dikelola secara profesional oleh Manajer Investasi. Dana masyarakat yang dikumpulkan di dalam reksa dana akan diinvestasikan ke berbagai instrumen keuangan untuk menghasilkan keuntungan di masa depan."
    },
    {
        title: "Apakah MyHero terdaftar di OJK?",
        body: "Aplikasi MyHero dimiliki dan dikelola oleh PT. Henan Putihrai Asset Management (Henan Asset), yaitu perusahaan pengelola reksa dana dengan Nomor Izin Usaha Manajer Investasi: KEP-044/BL/MI/2006 tanggal 14 Desember 2006, yang terdaftar serta diawasi oleh Otoritas Jasa Keuangan (OJK). "
    },
    {
        title: "Apakah investasi saya aman di MyHero? Bagaimana jika MyHero tutup?",
        body: "Seluruh dana investor reksa dana disimpan di Bank Kustodian, bukan di MyHero atau perusahaan Manajer Investasi. Bank Kustodian akan memastikan aset dana terpisah dari Manajer Investasi dan aset Bank Kustodian. Investor bisa cek kepemilikan reksa dana melalui Akses KSEI dan mencairkan reksa dana."
    },
]

export const FaqSection = () => {
    return (
        <HomeContainer>
            <HomeHeadings
                heading={<>Frequently Asked <Typography component="span" variant="h2" sx={{ display: "inline", color: "vibrant.main" }}>Questions</Typography></>}
                subheading={<>Tenang, kami akan membantumu</>}
            />
            <Stack
                sx={{
                    p: "0 16px"
                }}
            >
                <HomeAccordion datas={FaqDatas} />
            </Stack>
        </HomeContainer>
    )
}
import { useTheme } from "@emotion/react"
import { Box, Link, Stack, Typography, useMediaQuery } from "@mui/material"
import FormatDate from "../../services/FormatDate"
import { FacebookLogo, LinkedinLogoAlt, TwitterLogo, WhatsappLogo } from "../atoms/Icons"
import {
    TwitterShareButton,
    TwitterIcon,
    FacebookShareButton,
    FacebookIcon,
    LinkedinShareButton,
    LinkedinIcon,
    WhatsappShareButton,
    WhatsappIcon,
} from "react-share";


export const BlogPostHeader = ({ data }) => {
    const theme = useTheme()
    const isMobile = useMediaQuery(theme.breakpoints.down("md"))

    const socialsShare = [

        {
            component: TwitterShareButton,
            icon: <TwitterLogo />,
            color: "#1FBBEA",
        }
        ,

        {
            component: LinkedinShareButton,
            icon: <LinkedinLogoAlt />,
            color: "#088ADB",
        }
        ,

        {
            component: FacebookShareButton,
            icon: <FacebookLogo />,
            color: "#03559A",
        }
        ,

        {
            component: WhatsappShareButton,
            icon: <WhatsappLogo />,
            color: "#40D6A0",
        }
        ,
    ]

    return (
        <Stack
            sx={{
                width: "100%",
            }}
        >
            <Stack
                direction="column"
                sx={{
                    width: "100%",
                    py: "16px",
                    borderBottom: "1px solid",
                    borderColor: "grey.grey400",
                    gap: "8px"
                }}
            >
                <Typography variant="h2">{data.title}</Typography>
                <Typography variant="body1">{data.subtitle}</Typography>
            </Stack>
            <Stack
                direction={isMobile ? "column " : "row"}
                justifyContent="space-between"
                alignItems=""
                sx={{
                    py: "16px",
                    gap: "16px"
                }}
            >
                <Stack
                    direction="row"
                    sx={{
                        gap: "32px"
                    }}
                >
                    <Typography variant="body1" sx={{ color: "primary.main" }}>By {data.writer} </Typography>
                    <Typography variant="body1" sx={{ color: "grey.grey500" }}>{FormatDate(data.createdAt)}</Typography>
                </Stack>
                <Stack
                    direction="row"
                    sx={{
                        gap: "16px"
                    }}
                >
                    {socialsShare.map((item, idx) => {
                        const Component = item.component

                        return (
                            <Component
                                url={`https://blog.myhero.id/blog/${data?.slug}`}
                                source={`https://blog.myhero.id/blog/${data?.slug}`}
                                key={idx}
                                title={`Read MyHero's Blog Post '${data.title}' here!`}
                                hashtags={["HPAM", "MyHero"]}
                                summary={`${data?.subtitle}`}
                                sx={{
                                    cursor: "pointer"
                                }}
                            >
                                <Stack
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{
                                        width: "32px",
                                        height: "32px",
                                        borderRadius: "200%",
                                        bgcolor: `${item.color}`,
                                    }}
                                >
                                    {item.icon}
                                </Stack>
                            </Component>
                        )
                    })}
                </Stack>
            </Stack>
        </Stack>
    )
}
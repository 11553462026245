import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { apiUrls, apiHpam, engine } from "../services/apiService";

export const getAboutUsData = createAsyncThunk(
    "aboutUsData/getAboutUsData",
    async () => {
        const res = await apiHpam.get(apiUrls?.aboutUs.getAboutUs);
        return res.data.data;
    }
)

const aboutUsDataSlice = createSlice({
    name: "aboutUsData",
    initialState: {
        aboutUsData: [],
        loading: true,
        error: null,
    },

    reducers: {},
    extraReducers: {
        [getAboutUsData.pending]: (state) => {
            state.loading = true;
        },
        [getAboutUsData.fulfilled]: (state, action) => {
            state.loading = false;
            state.aboutUsData = action.payload;
        },
        [getAboutUsData.rejected]: (state, action) => {
            state.loading = false;
            state.error = action.payload;
        },
    },
})

export default aboutUsDataSlice.reducer

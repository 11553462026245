import { Box, Stack, Typography } from "@mui/material";
import formatNumber from "../../../services/formatNumber";

export default function SimulasiChartLegend({ payload, maxValues }) {
  const filteredPayload = payload.filter((item) => item.dataKey !== "range");
  return (
    <Stack direction="row" gap={4}>
      {filteredPayload.map((item, index) => {
        const { dataKey } = item;
        const label = dataKey === "pv" ? "MyHero" : "Nabung Biasa";

        return (
          <Stack key={dataKey} direction="row" gap={1}>
            <Box
              sx={{
                width: "12px",
                height: "12px",
                bgcolor: item.color,
                borderRadius: "50%",
                mt: 0.2,
              }}
            />
            <Stack>
              <Typography fontSize={14}>{label}</Typography>
              <Typography fontSize={14} color="secondary" fontWeight={500}>
                {formatNumber(maxValues[index])}
              </Typography>
            </Stack>
          </Stack>
        );
      })}
    </Stack>
  );
}

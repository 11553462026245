import { Button, Stack, Box, Typography, Link } from "@mui/material";
import { Link as RouterLink } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { ButtonText } from "./Typography";

export const ButtonPrimary = ({ children, href, hrefEx, sx }) => {
  return (
    <Link
      component={hrefEx ? "a" : RouterLink}
      to={href}
      href={hrefEx}
      sx={{
        textDecoration: "none",
        cursor: "pointer",
        ...sx,
      }}
    >
      <Stack
        alignItems="center"
        sx={{
          borderRadius: "8px",
          bgcolor: "primary.main",
          color: "white !important",
          p: "12px 24px",
          height: "max-content",
        }}
      >
        <ButtonText>{children}</ButtonText>
      </Stack>
    </Link>
  );
};

export const ButtonCategory = ({ children, href, sx, scroll, onClick }) => {
  return (
    <Box
      component={scroll ? ScrollLink : RouterLink}
      activeClass="active"
      to={href}
      spy={true}
      smooth={true}
      offset={-100}
      duration={500}
      onClick={onClick}
      sx={{
        textDecoration: "none",
        cursor: "pointer",
        borderRadius: "200px",
        width: "auto",
      }}
    >
      <Button
        disableElevation
        variant="outlined"
        sx={{
          p: "8px 24px",
          borderRadius: "200px",
          transition: "all 300ms",
          whiteSpace: "nowrap",
          gap: "8px",
          borderWidth: "2px",
          borderColor: "primary.main",
          "&:hover": {
            bgcolor: "primary.main",
            color: "#ffffff",
            borderWidth: "2px",
          },
          "& .MuiTypography-root": {
            fontWeight: 700,
            textTransform: "uppercase",
            fontSize: "14px",
          },
          ...sx,
        }}
      >
        {children}
      </Button>
    </Box>
  );
};

export const ButtonTextIcon = ({ children, icon, to }) => {
  return (
    <Link
      component={RouterLink}
      to={to}
      sx={{
        textDecoration: "none",
        cursor: "pointer",
        borderRadius: "200px",
      }}
    >
      <Stack>
        <Stack
          direction="row"
          sx={{
            gap: "8px",
          }}
        >
          <Typography variant="body2">{children}</Typography>
          <Box
            sx={{
              width: "auto",
              height: "24px",
            }}
          >
            {icon}
          </Box>
        </Stack>
      </Stack>
    </Link>
  );
};

import {
  Box,
  Link,
  Skeleton,
  Stack,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { Link as RouterLink } from "react-router-dom";
import FormatDate from "../../services/FormatDate";
import { useEffect, useState } from "react";

export const SquareCards = ({ illustration, title, body }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Grid2 item xs={12} lg={6}>
      <Stack
        justifyContent={isMobile ? "left " : "center"}
        alignItems="center"
        direction={isMobile ? "row" : "column"}
        sx={{
          p: isMobile ? "16px 32px" : "32px",
          gap: "32px",
          borderRadius: "8px",
          height: isMobile ? "auto" : "290px",
          bgcolor: "white",
          boxShadow: "1px 1px 16px rgba(0, 0, 0, 0.05)",
        }}
      >
        <Box component="img" src={illustration} width={100} height={100} />
        <Stack
          justifyContent={isMobile ? "left " : "center"}
          alignItems={isMobile ? "left " : "center"}
          sx={{
            gap: "8px",
          }}
        >
          <Typography
            variant="h5"
            sx={{
              textAlign: isMobile ? "left " : "center",
              fontSize: isMobile ? "16px" : "24px",
            }}
          >
            {title}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              textAlign: isMobile ? "left " : "center",
              fontSize: isMobile ? "12px" : "16px",
            }}
          >
            {body}
          </Typography>
        </Stack>
      </Stack>
    </Grid2>
  );
};

export const ProductCards = ({ datas }) => {
  return (
    <Box
      sx={{
        p: "24px 32px",
        borderRadius: "8px",
        boxShadow: "1px 1px 16px rgba(0, 0, 0, 0.08)",
        maxWidth: "540px",
        width: "100%",
        height: "220px",
      }}
    >
      <Stack justifyContent="space-between" height="100%" position="relative">
        <Stack>
          <Typography variant="h4">{datas.title}</Typography>
          <Typography variant="body2">{datas.desc}</Typography>
        </Stack>
        <Stack justifyContent="space-between" direction="row">
          <Stack>
            <Typography variant="body2" sx={{ fontWeight: "700" }}>
              AUM
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                color: "primary.main",
              }}
            >
              Rp. {datas.aum}
            </Typography>
          </Stack>
          <Stack alignItems="flex-end">
            <Typography variant="body2" sx={{ fontWeight: "700" }}>
              Return 1Y
            </Typography>
            <Typography
              sx={{
                fontWeight: "700",
                fontSize: "24px",
                color: "green.main",
              }}
            >
              {datas.returnp}%
            </Typography>
          </Stack>
        </Stack>
        <Box
          component="img"
          src={datas.icon}
          sx={{
            position: "absolute",
            top: 0,
            right: 0,
            width: "86px",
            height: "auto",
          }}
        />
      </Stack>
    </Box>
  );
};

export const BlogHeroCard = ({ data }) => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (data) {
      setLoading(false);
    }
  }, [data]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));
  return (
    <Link
      component={RouterLink}
      to={`/blog/${data.attributes.slug}`}
      sx={{
        cursor: "pointer",
        textDecoration: "none",
        height: "100%",
        width: "100%",
      }}
    >
      <Stack
        direction={isMobile ? "column" : "row"}
        alignItems="center"
        sx={{
          gap: "32px",
          width: "100%",
          height: "100%",
          flexShrink: 0,
        }}
      >
        {loading ? (
          <Skeleton width="100%" height={500} />
        ) : (
          <Box
            component="img"
            src={data.attributes.image.data.attributes.url}
            sx={{
              objectFit: "cover",
              textAlign: "left",
              bgcolor: "grey.grey200",
              height: "360px",
              width: "100%",
              maxWidth: "720px",
            }}
          />
        )}
        <Stack
          justifyContent="space-between"
          sx={{
            gap: "16px",
            py: "16px",
            maxWidth: "720px",
            width: "100%",
            height: "auto",
          }}
        >
          <Stack
            sx={{
              gap: "16px",
            }}
          >
            <Typography variant="h3">
              {loading ? <Skeleton height={40} /> : data.attributes.title}
            </Typography>
            <Typography
              variant="body2"
              sx={{
                // fontSize: "12px",
                color: "#000000",
              }}
            >
              {loading ? (
                <Box>
                  <Skeleton width="80%" />
                  <Skeleton width="80%" />
                  <Skeleton width="80%" />
                  <Skeleton width="80%" />
                </Box>
              ) : (
                data.attributes.subtitle
              )}
            </Typography>
          </Stack>
          {loading ? (
            <Skeleton width={100} />
          ) : (
            <Stack
              direction="row"
              sx={{
                gap: "16px",
              }}
            >
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                }}
              >
                {" "}
                {data.attributes.writer}{" "}
              </Typography>
              <Typography
                variant="body2"
                sx={{
                  fontSize: "12px",
                  color: "grey.grey500",
                }}
              >
                {" "}
                {FormatDate(data.attributes.publishedAt)}{" "}
              </Typography>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Link>
  );
};

import { Container, Stack } from "@mui/material";

export default function BigTextPageWrapper({ children }) {
  return (
    <Container
      sx={{
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Stack py={16} textAlign="center">
        {children}
      </Stack>
    </Container>
  );
}

import {
  Stack,
  Box,
  useTheme,
  useMediaQuery,
  Typography,
  Link,
} from "@mui/material";
import {
  PlayStore,
  AppStore,
  Ojk,
  Kominfo,
} from "../../../assets/graphic/logos";
import { AppHome } from "../../../assets/graphic/images";
import { HomeContainer } from "../../organisms/HomeContainer";
import { ButtonPrimary } from "../../atoms/Buttons";
import HeroSlider from "../../molecules/sliders/HeroSlider";
import configuration from "../../../services/configuration.json";

export const HeroSection = ({ promo, applink }) => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("lg"));

  return (
    <HomeContainer>
      <Stack
        direction={isMobile ? "column" : "row"}
        justifyContent={isMobile ? "center" : "space-between"}
        alignItems="center"
        width="100%"
        height="100%"
        sx={{
          gap: "64px",
        }}
      >
        <Stack
          alignItems={isMobile ? "center" : "flex-start"}
          sx={{
            gap: "24px",
          }}
        >
          <Stack
            alignItems={isMobile ? "center" : "flex-start"}
            sx={{
              gap: "16px",
              maxWidth: "420px",
              width: "100%",
            }}
          >
            <Typography
              variant={isMobile ? "h2" : "h1"}
              sx={{
                width: "fit-content",
                textAlign: isMobile ? "center" : "left",
              }}
            >
              Investing Should be{" "}
              <Typography
                component="span"
                variant={isMobile ? "h2" : "h1"}
                sx={{ display: "inline", color: "vibrant.main" }}
              >
                Easy
              </Typography>{" "}
              <br />
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: isMobile ? "none" : "flex",
              }}
            >
              Pakai MyHero, pasti gampang!
            </Typography>
          </Stack>
          {isMobile ? (
            <Stack
              alignItems="center"
              sx={{
                gap: "32px",
              }}
            >
              <Box
                component="img"
                src={AppHome}
                height={640}
                mt={1}
                sx={{
                  boxShadow: "1px 1px 32px rgba(0, 0, 0, 0.15)",
                  borderRadius: "16px",
                  maxWidth: "300px",
                  width: "100%",
                  objectFit: "contain",
                }}
              />
              <ButtonPrimary
                sx={{
                  width: "100%",
                  maxWidth: "350px",
                }}
                hrefEx={configuration.home.redirect_link}
              >
                Mulai Sekarang
              </ButtonPrimary>
            </Stack>
          ) : (
            <Stack
              direction="row"
              sx={{
                display: isMobile ? "none" : "flex",
                gap: "12px",
              }}
            >
              <Link href={applink.playStore}>
                <Box
                  component="img"
                  src={PlayStore}
                  width={179}
                  height={54}
                  sx={{
                    display: "inline-block",
                  }}
                />
              </Link>
              <Link href={applink.appStore}>
                <Box
                  component="img"
                  src={AppStore}
                  width={162}
                  height={54}
                  sx={{
                    display: "inline-block",
                  }}
                />
              </Link>
            </Stack>
          )}
          <Stack
            alignItems={isMobile ? "center" : "flex-start"}
            sx={{
              gap: "24px",
            }}
          >
            <Typography variant="body2" color="grey.grey500">
              Terdaftar dan diawasi oleh
            </Typography>
            <Stack
              direction="row"
              sx={{
                gap: "32px",
              }}
            >
              <Box component="img" src={Ojk} width={85.5} height={36} />
              <Box component="img" src={Kominfo} width={116.1} height={36} />
            </Stack>
          </Stack>
        </Stack>
        <Stack
          maxWidth="620px"
          width="100%"
          height={isMobile ? "auto" : "360px"}
          display={promo ? "flex" : "none"}
        >
          <HeroSlider promo={promo} />
        </Stack>
      </Stack>
    </HomeContainer>
  );
};

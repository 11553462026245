import { CircularProgress, useTheme } from "@mui/material"
import { useState, useEffect } from "react"
import { LineChart, Line, ResponsiveContainer, YAxis, XAxis} from "recharts"

const chartFilteredData = data => {
    const oneYearAgo = new Date();
    oneYearAgo.setFullYear(oneYearAgo.getFullYear() - 1);
    const filtered = [];
    let currentMonth;

    data.forEach(item => {
        const itemDate = new Date(item.tanggal);
        if (itemDate >= oneYearAgo) {
            if (currentMonth !== itemDate.getMonth()) {
                const year = itemDate.getFullYear();
                const monthNames = [
                    "Jan",
                    "Feb",
                    "Mar",
                    "Apr",
                    "May",
                    "Jun",
                    "Jul",
                    "Aug",
                    "Sep",
                    "Oct",
                    "Nov",
                    "Dec"
                ];
                const monthName = monthNames[itemDate.getMonth()];
                filtered.push({
                    tanggal: itemDate.getMonth() === 0 ? `${monthName} ${year}` : `${monthName}`,
                    pv: item.nav
                });
                currentMonth = itemDate.getMonth();
            }
        }
    });

    return filtered;
};

const ProductChart = ({ data, profitLoss, small }) => {
    const theme = useTheme()
    const [cleanData, setCleanData] = useState([]);

    useEffect(() => {
        if (data)
            setCleanData(chartFilteredData(data));
    }, [data]);

    return (
        <ResponsiveContainer height="100%" width="100%">
            <LineChart data={cleanData} margin={{ left: 0, right: 0, top: 0, bottom: 0 }}
                style={{
                    fontFamily: theme.typography.fontFamily,
                    cursor: "pointer"
                }}
            >
                {data ?
                    <Line type="monotone" dot={false} dataKey="pv" stroke={profitLoss ? theme.palette.green.main : theme.palette.red.main} strokeWidth={2} />
                    : <CircularProgress />}
                <YAxis type="number" hide={small} tickLine={false} domain={['auto', 'auto']} />
                <XAxis dataKey="tanggal" hide={small} tickLine={false} >
                </XAxis>
            </LineChart>
        </ResponsiveContainer>
    )
}

export default ProductChart
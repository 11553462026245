import { useTheme } from "@mui/material";
import React from "react";
import VerificationCard from "../../organisms/VerificationCard";

export default function FailedPage({subtitle}) {
    const theme = useTheme()
    React.useEffect(() => {
        setTimeout(() => {
        window.location.href = "/";
        }, 5000);
    }, []);

    return(
        <VerificationCard
            title="Failed!"
            titleColor={theme.palette.error.main}
            subtitle={subtitle}
        />
    )
}
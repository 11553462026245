import Stack from "@mui/material/Stack";
import useMediaQuery from "@mui/material/useMediaQuery";
import PromoContent from "../../templates/promo/PromoContent";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { getPromoData } from "../../../redux/promoDataSlice";
import { useEffect } from "react";
import FullWidthBanner from "../../molecules/banner/FullWidthBanner";
import PromoButtonBar from "../../molecules/PromoButtonBar";

const Promo = () => {
  const { slug } = useParams();
  const dispatch = useDispatch();
  const promoData = useSelector((state) => state.promoData.promoData);
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("md"));

  useEffect(() => {
    dispatch(getPromoData(slug));
  }, [dispatch, slug]);

  return (
    <Stack>
      <FullWidthBanner
        src={
          isMobile
            ? promoData?.promo_image_mobile?.data?.attributes?.url
            : promoData?.promo_image?.data?.attributes?.url
        }
        alt={promoData?.promo_name}
      />
      <PromoButtonBar datas={promoData.promo_contents} />
      <PromoContent datas={promoData.promo_contents} />
    </Stack>
  );
};

export default Promo;

import Stack from "@mui/material/Stack";
import NilaiKami from "../../templates/tentang-kami/NilaiKami";
import TentangKamiHero from "../../templates/tentang-kami/TentangKamiHero";
import TentangKamiNumbers from "../../templates/tentang-kami/TentangKamiNumbers";

const TentangKami = () => {

    return (
        <Stack>
            <TentangKamiHero />
            <TentangKamiNumbers />
            <NilaiKami />
        </Stack>
    )
}

export default TentangKami
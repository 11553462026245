import { Button, Box, Stack, Link, Typography } from "@mui/material";
import { HomeContainer } from "../../organisms/HomeContainer";
import { PromoSlider } from "../../organisms/promo/PromoSlider";
import { useEffect, useRef } from "react";
import TwoElipse from "../../atoms/TwoElipse";

export default function PromoChallenge({ challengeContent }) {
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  return (
    <Stack
      sx={{
        bgcolor: "vibrant.light",
        overflow: "hidden",
        position: "relative",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          zIndex: 1,
          left: { xs: "50%", lg: "-50%" },
          width: { xs: "250%", lg: "100%" },
          top: { xs: "-70%", lg: "50%" },
          transform: { xs: "translateX(-50%)", lg: "translateY(-50%)" },
          height: { xs: "120%", lg: "250%" },
          borderRadius: "50%",
          bgcolor: "primary.main",
          overflow: "hidden",
        }}
      >
        <Box
          sx={{
            height: { xs: "200%", lg: "100%" },
            width: "100%",
            rotate: { xs: "-270deg", lg: "-180deg" },
            background:
              "linear-gradient(214deg, rgba(82, 193, 225, 0.05) 31.81%, rgba(87, 205, 238, 0.30) 83.45%);",
          }}
        />
      </Box>
      <Box
        sx={{
          position: "absolute",
          bottom: { xs: "auto", lg: -16 },
          left: { xs: -32, lg: 360 },
          top: { xs: 128, lg: "auto" },
          rotate: { xs: "90deg", lg: "0deg" },
          zIndex: 3,
        }}
      >
        <TwoElipse />
      </Box>
      <HomeContainer
        sx={{
          p: { md: "0 120px", xs: "16px 16px" },
        }}
      >
        <Stack
          width="100%"
          zIndex={2}
          py={6}
          position={{ xs: "static", lg: "relative" }}
          direction={{ xs: "column", lg: "row" }}
        >
          <Stack
            width={{ xs: "100%", lg: "30%" }}
            gap={2.5}
            color="#FFFFFF"
            pt={{ xs: 0, lg: 8 }}
            pb={{ xs: 4, lg: 0 }}
          >
            <Typography fontSize={32} fontWeight={700} lineHeight={1.2}>
              {challengeContent.heading}
            </Typography>
            <Typography>{challengeContent.subheading}</Typography>
            <Link
                href={challengeContent.tncLink}
                sx={{
                  width: "fit-content",
                }}
              >
                <Button
                  variant="contained"
                  color="vibrant"
                  disableElevation
                  size="large"
                >
                  Read More
                </Button>
              </Link>
          </Stack>
          <Stack
            width={{ xs: "100%", lg: "70%" }}
            sx={{
              position: { xs: "relative", lg: "static" },
            }}
          >
            <PromoSlider
              slides={challengeContent.slides}
              swiperRef={{
                prevRef,
                nextRef,
              }}
            />
          </Stack>
        </Stack>
      </HomeContainer>
    </Stack>
  );
}

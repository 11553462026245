import Stack from "@mui/material/Stack";
import ProductSlider from "../molecules/sliders/ProductSlider";
import TitleFormatter from "../../services/TitleFormatter";
import { Element } from "react-scroll";
import { Typography } from "@mui/material";
import BlogCards from "../molecules/cards/BlogCards";

export const BlogArticlesRow = ({ data }) => {
  return (
    <Element
      name={data.category}
      style={{
        width: "100%",
      }}
    >
      <Stack
        sx={{
          width: "100%",
          height: "auto",
          gap: "32px",
        }}
      >
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="h2">
            <TitleFormatter input={data.category} />
          </Typography>
        </Stack>
        <Stack
          direction="row"
          sx={{
            gap: "16px",
            width: "100%",
            height: "400px",
          }}
        >
          <ProductSlider datas={data.blogs} component={BlogCards} />
        </Stack>
      </Stack>
    </Element>
  );
};
